import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { createActionSet } from "@somewear/model";

import type { WorkspaceState } from "./workspace.state";

export interface IWorkspaceKey {
	id: string;
	key?: string;
}

export interface IWorkspaceToken {
	id: string;
	url?: string;
}

type WorkspaceKeyOrToken = IWorkspaceKey | IWorkspaceToken;

const adapter = createEntityAdapter<WorkspaceKeyOrToken>({
	selectId: (workspace) => workspace.id,
});

export const workspaceKeyActions = {
	generateKey: createActionSet<undefined, IWorkspaceKey>("workspace/key/generate"),
	generateToken: createActionSet<undefined, IWorkspaceToken>("workspace/token/generate"),
};

// Rename the exports for readability in component usage
export const { selectAll: selectAllWorkspaceKeys } = adapter.getSelectors(
	(state: WorkspaceState) => state.workspaceKeys
);

export const workspaceKeysSlice = createSlice({
	name: "workspaceKeys",
	initialState: adapter.getInitialState(),
	reducers: {
		_: () => {},
	},
	extraReducers: (builder) => {
		builder.addCase(workspaceKeyActions.generateKey.fulfilled, (state, action) => {
			adapter.upsertOne(state, action.payload.data);
		});
		builder.addCase(workspaceKeyActions.generateToken.fulfilled, (state, action) => {
			adapter.upsertOne(state, action.payload.data);
		});
	},
});
