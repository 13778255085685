import type { OnCompleteHandler } from "@somewear/model";
import type { ISomewearNotification } from "@somewear/notification";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import type { NotificationState } from "./notification.state";
import {
	isErrorNotification,
	isSuccessNotification,
	selectNotificationByRequestId,
} from "./notificationsSlice";

export const useSelectNotificationById = (
	requestId?: string
): ISomewearNotification | undefined => {
	return useSelector((state: NotificationState) =>
		requestId === undefined ? undefined : selectNotificationByRequestId(state, requestId)
	);
};

type RequestState = "pending" | "success" | "error";

export function useOnCompleteHandler(
	onComplete: OnCompleteHandler | undefined,
	notification: ISomewearNotification | undefined,
	requestId: string | undefined
) {
	const [state, setState] = useState<RequestState>("pending");

	useEffect(() => {
		if (onComplete === undefined || notification === undefined) return;

		if (isSuccessNotification(notification)) {
			// avoid the effect double run in development (react 18)
			if (state === "success") return;
			setState("success");
			onComplete(true, notification.data, requestId);
		} else if (isErrorNotification(notification)) {
			setState("error");
			onComplete(false, notification);
		} else {
			setState("pending");
		}
	}, [state, notification, onComplete, requestId]);
}
