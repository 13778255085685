import {
	GetOrganizationIntegrationAccountsResponse,
	IdentityRecord,
	OrganizationRole,
} from "@somewear/api";
import {
	selectAllIdentities,
	selectAllWorkspaceMembers,
	selectJoinedWorkspaceIds,
} from "@somewear/asset";
import { selectActiveOrganizationId } from "@somewear/auth";
import { identityIsIntegration, type IIdentity } from "@somewear/model";
import {
	selectActiveOrganizationWorkspaces,
	selectHasPersonalWorkspace,
	selectNonActiveOrganizationWorkspaces,
} from "@somewear/workspace";
import _ from "lodash";
import { createSelector } from "reselect";

import type { IOrganization, OrganizationPickerOption } from "./index";
import { selectAllOrganizations } from "./index";

export const selectActiveOrganization = createSelector(
	[selectAllOrganizations, selectActiveOrganizationId],
	(orgs, id) => {
		if (id === "-1")
			return {
				id: "-1",
				name: "Personal",
			} as IOrganization;
		return orgs.find((org) => org.id === id);
	}
);

export const selectHasActiveOrganization = createSelector(
	[selectActiveOrganization],
	(organization) => {
		return organization !== undefined && organization.id !== "-1";
	}
);

export const selectIdentitiesForActiveOrganization = createSelector(
	[selectAllIdentities, selectActiveOrganizationId],
	(identities, activeOrgId) => {
		return identities.filter((identity) => {
			if (identity.organizationId === activeOrgId) return true;

			if (identity.type === IdentityRecord.Type.USER) {
				return (
					identity.organizationRole !== undefined &&
					identity.organizationRole !== OrganizationRole.ORGANIZATIONROLENONE
				);
			}

			return false;
		});
		// return identities.filter((identity) => identity.organizationId === activeOrgId);
	}
);

export const selectAllOrganizationUsers = createSelector(
	[selectIdentitiesForActiveOrganization],
	(identities) => {
		return identities
			.filter((identity) => identity.type === IdentityRecord.Type.USER)
			.map((identity) => ({
				...identity,
				displayName: identity.fullName || identity.email,
			}));
	}
);

export const selectAllOrganizationIntegrations = createSelector(
	[selectIdentitiesForActiveOrganization],
	(identities) => {
		return identities.filter(identityIsIntegration);
	}
);

export const selectAllOrganizationTakServerIntegrations = createSelector(
	[selectAllOrganizationIntegrations],
	(identities) => {
		return identities.filter(
			(it) =>
				it.integrationType ===
				GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationType.TAK
		);
	}
);

export const selectAllOrganizationMembers = createSelector(
	[selectAllOrganizationUsers],
	(members) => {
		return members.filter(
			(member) => member.organizationRole !== OrganizationRole.ORGANIZATIONROLENONE
		);
	}
);

export const selectAllOrganizationMembersNotInActiveWorkspace = createSelector(
	[selectAllOrganizationUsers, selectAllWorkspaceMembers],
	(orgMembers, wsMembers) => {
		const workspaceIdentityIds = wsMembers.map((member) => member.identityId);
		const orgIdentityIds = orgMembers.map((orgMember) => orgMember.id);
		const eligibleIds = _.difference(orgIdentityIds, workspaceIdentityIds);
		return orgMembers.filter((orgMember) => eligibleIds.includes(orgMember.id));
	}
);

export const selectAllOrganizationGuests = createSelector(
	[selectAllOrganizationUsers],
	(members) => {
		return members.filter(
			(member) => member.organizationRole === OrganizationRole.ORGANIZATIONROLENONE
		);
	}
);

export const selectAllOrganizationResources = createSelector(
	[selectIdentitiesForActiveOrganization],
	(identities) => {
		return identities
			.filter((identity) => identity.type === IdentityRecord.Type.RESOURCE)
			.map((identity) => ({
				...identity,
				displayName: identity.fullName || identity.email,
			}));
	}
);

const selectAllOrganizationTakIdentities = createSelector(
	[selectIdentitiesForActiveOrganization],
	(identities) =>
		identities.filter((it) => it.externalId !== undefined && it.externalId.startsWith("tak:"))
);

export const selectOrganizationTakUserIdentities = createSelector(
	[selectAllOrganizationTakIdentities],
	(identities) => identities.filter((it) => it.type === IdentityRecord.Type.USER)
);

export const selectOrganizationTakResourceIdentities = createSelector(
	[selectAllOrganizationTakIdentities],
	(identities) => identities.filter((it) => it.type === IdentityRecord.Type.RESOURCE)
);

export type IdentityWorkspaceMapping = {
	identity: IIdentity;
	workspaceId?: string;
};

export const selectAllOrganizationWorkspacesSorted = createSelector(
	[selectActiveOrganizationWorkspaces, selectJoinedWorkspaceIds],
	(workspaces, joinedWorkspaces) => {
		return [...workspaces].sort((a, b) => {
			const memberOfA = joinedWorkspaces.includes(a.id);
			const memberOfB = joinedWorkspaces.includes(b.id);
			if (memberOfA && !memberOfB) return -1;
			if (!memberOfA && memberOfB) return 1;
			const aName = a.name.toLowerCase();
			const bName = b.name.toLowerCase();
			if (aName && bName) {
				if (aName < bName) return -1;
				else if (aName > bName) return 1;
			} else if (aName) {
				return -1;
			} else if (bName) {
				return 1;
			}
			return 0;
		});
	}
);

export const selectJoinedOrganizationWorkspacesSortedByName = createSelector(
	[selectAllOrganizationWorkspacesSorted, selectJoinedWorkspaceIds],
	(workspaces, joinedIds) => {
		return workspaces.filter((it) => joinedIds.includes(it.id));
	}
);

export const selectOtherOrganizationWorkspacesSortedByName = createSelector(
	[selectAllOrganizationWorkspacesSorted, selectJoinedWorkspaceIds],
	(workspaces, joinedIds) => {
		return workspaces.filter((it) => !joinedIds.includes(it.id));
	}
);

export const selectAllNonOrganizationWorkspacesSortedByName = createSelector(
	[selectNonActiveOrganizationWorkspaces],
	(workspaces) => {
		return workspaces.sort((a, b) => {
			const aName = a.name.toLowerCase();
			const bName = b.name.toLowerCase();
			if (aName && bName) {
				if (aName < bName) return -1;
				else if (aName > bName) return 1;
			} else if (aName) {
				return -1;
			} else if (bName) {
				return 1;
			}
			return 0;
		});
	}
);

export const selectOrganizationPickerOptions = createSelector(
	[selectAllOrganizations, selectHasPersonalWorkspace],
	(organizations, hasPersonalWorkspace) => {
		const sortedOrganizations = organizations.slice().sort((a, b) => {
			const aName = a.name;
			const bName = b.name;
			if (aName < bName) return -1;
			if (aName > bName) return 1;
			return 0;
		});

		const baseOptions: OrganizationPickerOption[] = hasPersonalWorkspace
			? [{ id: "-1", name: "Personal" }]
			: [];

		const organizationOptions: OrganizationPickerOption[] = baseOptions.concat(
			sortedOrganizations.map((w) => {
				return { id: w.id, name: w.name };
			})
		);
		return organizationOptions;
	}
);

export const selectActiveOrganizationPickerOption = createSelector(
	[selectOrganizationPickerOptions, selectActiveOrganizationId],
	(options, id) => options.find((it) => it.id === id)
);
