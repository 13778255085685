import type { PayloadAction } from "@reduxjs/toolkit";
import type { GetWaypointsResponse, RouteResponse, UserResponse } from "@somewear/api";
import { addContactsFromUserAccounts } from "@somewear/asset";
import { grpc, someGrpc } from "@somewear/grpc";
import type { ActionSetEpic } from "@somewear/model";
import { createActionSetEpicHandler } from "@somewear/model";
import { waypointActions } from "@somewear/waypoint";
import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import { filter, from, map, mergeMap } from "rxjs";

const fetchWaypointsEpic: ActionSetEpic<void, GetWaypointsResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, waypointActions.fetch, (payload) =>
		grpc.prepareRequest(someGrpc.fetchWaypoints)
	);
};

const fetchWaypointsFulfilledEpic: Epic<
	PayloadAction<RouteResponse.AsObject[] | UserResponse.AsObject[]>,
	PayloadAction<RouteResponse.AsObject[] | UserResponse.AsObject[]>
> = (action$, state$) =>
	action$.pipe(
		filter(waypointActions.fetch.fulfilled.match),
		map((response) => {
			return response.payload.data.routesList;
		}),
		mergeMap((routeList) =>
			from([
				addContactsFromUserAccounts(routeList.mapNotNull((routes) => routes.owner)),
				waypointActions.apiRoutesSuccess(routeList),
			])
		)
	);

export const waypointEpics = combineEpics<any, any, any>(
	fetchWaypointsEpic,
	fetchWaypointsFulfilledEpic
);
