import { createSelector } from "reselect";

import { selectAllConversations } from "./conversationsSlice";

export const selectAllContactConversations = createSelector(
	selectAllConversations,
	(conversations) => {
		return conversations.filter((conv) => conv.participantId !== undefined);
	}
);

export const selectAllWorkspaceConversations = createSelector(
	selectAllConversations,
	(conversations) => {
		return conversations.filter((conv) => conv.workspaceId !== undefined);
	}
);
