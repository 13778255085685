import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createSlice } from "@reduxjs/toolkit";
import type { MessageResponse, Timestamp } from "@somewear/api";
import type {
	IError,
	IMessage,
	MessageRequestWithStateAndTmpId,
	MessagesRequestPayload,
} from "@somewear/model";
import { createActionSet } from "@somewear/model";

import { conversationActions, getConversationKey } from "../conversation";
import type { IGetMessagesResponse } from "../messagesSlice";

export interface ConversationOrWorkspace {
	conversationId?: string;
	workspaceId?: string;
}

export interface ReadTimestamps {
	conversations: Record<string, Timestamp.AsObject>;
	workspaces: Record<string, Timestamp.AsObject>;
}

export const WORKSPACE_ID_ALL = "_all";

type WorkspaceSidebarView = "members" | "files";

type IMessagingAppState = {
	// read timestamp indexed by conversation id
	readTimestamps: ReadTimestamps;
	selectedConversationKey?: string;
	selectedMessage?: string;
	conversationsLoaded: boolean;
	timestampsLoaded: boolean;
	recipient: Recipient;
	workspaceSidebarView: WorkspaceSidebarView | null;
	filteredWorkspaceId: string;
};

const initialState: IMessagingAppState = {
	readTimestamps: {
		conversations: {},
		workspaces: {},
	},
	selectedConversationKey: undefined,
	selectedMessage: undefined,
	conversationsLoaded: false,
	timestampsLoaded: false,
	recipient: { valid: false },
	filteredWorkspaceId: WORKSPACE_ID_ALL,
	workspaceSidebarView: null,
};

export interface MessageResponsePayload {
	conversationInfo: ConversationOrWorkspace;
	messages: MessageResponse.AsObject[];
}

export interface MessageResponseWithState extends IMessage {
	state?: string;
}

export interface ConversationTimestamp {
	conversationId: string;
	timestamp: Timestamp.AsObject;
}

export interface Recipient {
	valid: boolean;
	email?: string;
	phone?: string;
	id?: string;
}

export const emitMessageReceivedFromStream = createAction<IMessage>("messaging/messageFromStream");

/*export const apiDeleteConversationRequest = createAction<string>(
	"messaging/apiDeleteConversationRequest"
);*/

export const messageActions = {
	send: createActionSet<MessageRequestWithStateAndTmpId, IMessage>("messages/send"),
	get: createActionSet<MessagesRequestPayload, IGetMessagesResponse>("messages/get"),
};

export const messagingSlice = createSlice({
	name: "messages",
	initialState,
	reducers: {
		setSelectedConversationKey(state, action: PayloadAction<string | undefined>) {
			if (action.payload === undefined) {
				state.selectedConversationKey = action.payload;
			} else if (action.payload.startsWith("w_") || action.payload.startsWith("c_")) {
				state.selectedConversationKey = action.payload;
			} else {
				console.error(`Invalid conversation key: ${action.payload}`);
			}
		},
		setSelectedMessage(state, action: PayloadAction<string | undefined>) {
			state.selectedMessage = action.payload;
		},
		apiDeleteConversationSuccess() {},
		apiDeleteConversationError(state, action: PayloadAction<IError>) {},
		setRecipientValid(state, action: PayloadAction<Recipient>) {
			state.recipient = action.payload;
		},
		/*apiCreateConversationRequest(state, action: PayloadAction<MessageDto.AsObject>) {},
		apiCreateConversationSuccess(
			state,
			action: PayloadAction<ConversationResponseWithMessage.AsObject>
		) {
			state.selectedConversation = action.payload.conversation;
		},
		apiCreateConversationError(state, action: PayloadAction<IError>) {
			window.alert("There was an error creating the conversation");
		},*/
		setWorkspaceSidebarView(state, action: PayloadAction<WorkspaceSidebarView | null>) {
			state.workspaceSidebarView = action.payload;
		},
		fetchTrackingFilters() {},
		fetchTrackingSettings() {},
		fetchWorkspaceFilters() {},
		getReadTimestamps() {},
		setReadTimestamps(state, action: PayloadAction<ReadTimestamps>) {
			state.readTimestamps = action.payload;
			if (action.payload.workspaces === undefined) {
				state.readTimestamps.workspaces = {};
			} else if (action.payload.conversations === undefined) {
				state.readTimestamps.conversations = {};
			}
			state.timestampsLoaded = true;
		},
		setConversationTimestampRequest(state, action: PayloadAction<ConversationTimestamp>) {},
		setConversationTimestampSuccess(state, action: PayloadAction<ConversationTimestamp>) {
			state.readTimestamps.conversations[action.payload.conversationId] =
				action.payload.timestamp;
		},
		setWorkspaceTimestampRequest(state, action: PayloadAction<ConversationTimestamp>) {},
		setWorkspaceTimestampSuccess(state, action: PayloadAction<ConversationTimestamp>) {
			state.readTimestamps.workspaces[action.payload.conversationId] =
				action.payload.timestamp;
		},
		setFilteredWorkspaceId(state, action: PayloadAction<string>) {
			state.filteredWorkspaceId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(conversationActions.fetch.fulfilled, (state, action) => {
			state.conversationsLoaded = true;
		});
		builder.addCase(conversationActions.delete.request, (state, action) => {
			state.selectedConversationKey = undefined;
		});
		builder.addCase(conversationActions.delete.fulfilled, (state, action) => {
			state.selectedConversationKey = undefined;
		});
		builder.addCase(conversationActions.create.fulfilled, (state, action) => {
			if (action.payload.data.conversation !== undefined) {
				state.selectedConversationKey = getConversationKey(
					action.payload.data.conversation
				);
			}
		});
	},
});
