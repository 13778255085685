import type {
	CreateOrganizationApiClientRequest,
	CreateOrganizationApiClientResponse,
	DeleteOrganizationApiClientRequest,
	DeleteOrganizationApiClientResponse,
	GetOrganizationApiClientsResponse,
} from "@somewear/api/src/proto/api/organization_pb";
import { createActionSet } from "@somewear/model";

export const apiKeysActions = {
	getOrganizationApiKeys: createActionSet<void, GetOrganizationApiClientsResponse.AsObject>(
		"organization/apikeys/get"
	),
	createOrganizationApiKey: createActionSet<
		CreateOrganizationApiClientRequest.AsObject,
		CreateOrganizationApiClientResponse.AsObject
	>("organization/apikeys/create"),
	deleteOrganizationApiKey: createActionSet<
		DeleteOrganizationApiClientRequest.AsObject,
		DeleteOrganizationApiClientResponse.AsObject
	>("organization/apikeys/delete"),
};
