import type { SignInResponse } from "@somewear/api";
import { SignInRequest } from "@somewear/api";
import { grpc, someGrpc } from "@somewear/grpc";
import { StoreController } from "@somewear/storage";
import jwt_decode from "jwt-decode";
import type { Observable, Observer } from "rxjs";
import { BehaviorSubject, defer, of, Subject } from "rxjs";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError } from "rxjs/internal/operators/catchError";
import { delay, map, switchMap, take } from "rxjs/operators";

import { Api } from "./Api";
import type { IAuthService, IAuthUser } from "./AuthUtil";
import type { AuthModel, AuthModelV2, AuthRecord, TokenClaims } from "./store";
import { convertRecordToModel, isModelV1, isModelV2, isRecordV1, isRecordV2 } from "./store";

const ACCESS_TOKEN_KEY = "swl_access_token";

function getAccessToken(subject: AuthModel | undefined): string | undefined {
	if (isModelV1(subject)) {
		return subject.token;
	} else if (isModelV2(subject)) {
		return subject.accessToken;
	}
	return undefined;
}

const auth$ = new BehaviorSubject<AuthModel | undefined>(undefined);
const authChange$ = new Subject<AuthModel | undefined>();

authChange$.subscribe(auth$);

// avoid eslint converting this to a const
let AUTH_VERSION: 1 | 2 = 1;
AUTH_VERSION = 2;

const init = () => {
	const authInitObs: Observer<AuthRecord | undefined> = {
		next: function (storedToken): void {
			if (isRecordV1(storedToken)) {
				if (AUTH_VERSION === 2) {
					// there is no upgrade path from v1 to v2, the user will need to log in again
					initializeToken(undefined);
				} else {
					// continue using v1
					initializeToken({
						token: storedToken.token,
						uid: storedToken.uid,
					});
				}
			} else if (isRecordV2(storedToken)) {
				initializeToken({
					accessToken: storedToken.accessToken,
					refreshToken: storedToken.refreshToken,
					accessExpiration: storedToken.accessExpiration,
					uid: storedToken.uid,
				});
			} else {
				initializeToken(storedToken);
			}
		},
		error: function (err): void {
			initializeToken(undefined);
		},
		complete: function (): void {
			console.log("auth init complete");
		},
	};

	StoreController.getItem<AuthRecord | undefined>(
		StoreController.STORE_AUTH_DATA,
		ACCESS_TOKEN_KEY
	).subscribe(authInitObs);
};

const expiration$ = new Subject<AuthModelV2>();

expiration$
	.pipe(
		switchMap((auth) => {
			return of(auth).pipe(
				delay((auth.accessExpiration / 2) * 1000), // refresh the token when it gets halfway to expiring
				refreshAccessToken()
			);
		})
	)
	.subscribe((auth) => {
		console.log("refreshed token", auth);
	});

function refreshAccessToken() {
	return switchMap((subject: AuthModelV2) => {
		return grpc
			.prepareRequestWithPayload(
				someGrpc.refreshAccessToken,
				subject.refreshToken,
				true,
				true
			)
			.pipe(handleSignInResponse(subject.user.email ?? ""));
	});
}

function subscribeToAuthChanges() {
	const storeTokenObs: Observer<AuthRecord | void> = {
		next: function (value): void {
			console.log("successfully removed token");
		},
		error: function (err: any): void {
			console.error("unable to remove token");
		},
		complete: function (): void {},
	};

	authChange$.subscribe((auth) => {
		if (auth === undefined) {
			StoreController.removeItem(StoreController.STORE_AUTH_DATA, ACCESS_TOKEN_KEY).subscribe(
				storeTokenObs
			);
		} else if (isModelV1(auth)) {
			const decoded = jwt_decode<TokenClaims>(auth.token);
			StoreController.setItem<AuthRecord>(StoreController.STORE_AUTH_DATA, ACCESS_TOKEN_KEY, {
				token: auth.token,
				uid: decoded.uid,
			}).subscribe(storeTokenObs);
		} else if (isModelV2(auth)) {
			// v2
			const decoded = jwt_decode<TokenClaims>(auth.accessToken);
			StoreController.setItem<AuthRecord>(StoreController.STORE_AUTH_DATA, ACCESS_TOKEN_KEY, {
				accessToken: auth.accessToken,
				refreshToken: auth.refreshToken,
				accessExpiration: auth.accessExpiration,
				uid: decoded.uid,
			}).subscribe(storeTokenObs);

			expiration$.next(auth);
		} else {
			console.warn("unknown auth version");
		}
	});
}

function initializeToken(authModel: AuthRecord | undefined) {
	subscribeToAuthChanges();

	const value = convertRecordToModel(authModel);
	authChange$.next(value);
}

const handleSignInResponse = (email: string) => {
	return map((r: SignInResponse) => {
		const decoded = jwt_decode<TokenClaims>(r.getAccessToken());

		// @ts-ignore
		if (AUTH_VERSION === 1) {
			authChange$.next({
				token: r.getAccessToken(),
				user: { id: decoded.uid, displayName: email, username: email, isAnonymous: false },
			});
		} else if (AUTH_VERSION === 2) {
			authChange$.next({
				accessToken: r.getAccessToken(),
				refreshToken: r.getRefreshToken(),
				accessExpiration: r.getAccessExpiration(),
				user: { id: decoded.uid, displayName: email, username: email, isAnonymous: false },
			});
		}

		return {
			id: decoded.uid,
			displayName: email,
			username: email,
		} as IAuthUser;
	});
};

export interface ISomewearAuthService extends IAuthService {
	setCurrentAuthUser(user: IAuthUser): void;
	refreshAccessToken$(): Observable<IAuthUser | undefined>;
}

export const SomewearAuthService: ISomewearAuthService = {
	initialize: () => {
		init();
	},
	signInWithEmailAndPassword$: (args) => {
		const request = new SignInRequest();
		request.setUsername(args.email);
		request.setPassword(args.password);
		return Api.signIn(request).pipe(handleSignInResponse(args.email));
	},
	reauthenticate$: (currentPassword) => {
		const username = auth$.value?.user.username;

		if (!username) {
			return throwError(() => "No current user");
		}

		const request = new SignInRequest();
		request.setUsername(username);
		request.setPassword(currentPassword);

		return Api.signIn(request).pipe(
			map(() => true),
			catchError((error) => {
				console.error("Reauthentication failed:", error);
				return throwError(() => new Error("Current password is invalid"));
			})
		);
	},
	createUserWithEmailAndPassword$: (args) => {
		const request = new SignInRequest();
		request.setUsername(args.email);
		request.setPassword(args.password);
		return Api.signUp(request).pipe(handleSignInResponse(args.email));
	},

	signInAnonymously$: () => of(),
	sendPasswordResetEmail$: (email: string) => of(),
	sendEmailVerification$: () => of(),
	updateProfile$: (displayName: string) => of(),
	signOut$: () =>
		defer(() => {
			authChange$.next(undefined);
			return of();
		}),
	getUserIdToken$: () =>
		auth$.pipe(
			map((it) => getAccessToken(it)),
			take(1) // make sure this observable completes when we get the value
		),
	getTokenString$: () =>
		SomewearAuthService.getUserIdToken$().pipe(map((token) => `Token ${token}`)),
	getCurrentAuthUser: () => auth$.value?.user,
	reloadUser: () => {},
	onAuthStateChanged: (obs$: Subject<IAuthUser | undefined>) => {
		authChange$.pipe(map((it) => it?.user)).subscribe((user) => {
			obs$.next(user);
		});
		// on unregister
		return () => {
			obs$.next(undefined);
		};
	},
	setCurrentAuthUser: (user: IAuthUser) => {
		const subject = auth$.value!;

		if (isModelV1(subject)) {
			const token = subject.token;
			authChange$.next({
				token: token,
				user: user,
			});
		} else if (isModelV2(subject)) {
			const accessToken = subject.accessToken;
			const accessExpiration = subject.accessExpiration;
			const refreshToken = subject.refreshToken;
			authChange$.next({
				accessToken: accessToken,
				accessExpiration: accessExpiration,
				refreshToken: refreshToken,
				user: user,
			});
		}
	},
	refreshAccessToken$: () => {
		const subject = auth$.value;
		if (!isModelV2(subject)) return of(undefined);

		return of(subject).pipe(refreshAccessToken());
	},
};

export function isSomewearAuthService(
	authService: IAuthService | undefined
): authService is ISomewearAuthService {
	if (authService === undefined) return false;
	return (authService as ISomewearAuthService).refreshAccessToken$ !== undefined;
}
