export namespace SomewearFeatures {
	// export const MAP_LAYERS = "mapLayers";
	// export const CALLSIGN_CHANGES = "callsignChanges";
	// export const DIY_WORKSPACE = "diyWorkspace";
	export const DIY_ORGANIZATION = "web_diyOrganization";
	export const ORGANIZATION_REMOVE_MEMBER = "web_organizationRemoveMember";
	export const ORGANIZATION_REMOVE_WORKSPACE = "web_organizationRemoveWorkspace";
	export const ORGANIZATION_LEAVE_WORKSPACE = "web_organizationLeaveWorkspace";
	export const ORGANIZATION_JOIN_WORKSPACE = "web_organizationJoinWorkspace";
	export const ORGANIZATION_ADD_MEMBER = "web_organizationAddMember";
	export const ORGANIZATION_ADD_WORKSPACE = "web_organizationAddWorkspace";
	export const ORGANIZATION_RESOURCES_TAB = "web_organizationResourcesTab";
	export const WORKSPACE_REMOVE_MEMBER = "web_workspaceRemoveMember";
	// export const WORKSPACE_ADD_MEMBER = "workspaceAddMember";
	// export const WORKSPACE_TRANSFER_DEVICE = "workspaceTransferDevice";
	// export const WORKSPACE_UNREGISTER_DEVICE = "workspaceUnregisterDevice";
	// export const REQUEST_WORKSPACE_KEY = "web_requestWorkspaceKey";
	// export const JOIN_WORKSPACE = "joinWorkspace";
	// export const WORKSPACE_ASSIGN_DEVICE_TO_MEMBER = "web_workspaceAssignDeviceToMember";
	export const WORKSPACE_RESOURCES_TAB = "web_workspaceResourcesTab";
	export const WORKSPACE_RENAME_MEMBER = "web_workspaceRenameMember";
	export const ORGANIZATION_RENAME_MEMBER = "web_organizationRenameMember";
	export const ORGANIZATION_EDIT_BILLING = "web_organizationEditBilling";
	export const ORGANIZATION_DEVICE_USAGE_ON = "web_organizationDeviceUsage";
	export const ORGANIZATION_DEVICE_DETAILS_ON = "web_organizationDeviceDetails";

	export const ORGANIZATION_DEVICE_BORROWING_ON = "web_organizationBorrowedDevices";

	export const ENABLE_ORGANIZATION_DEVICE_ACTIVATION_STATE =
		"web_organizationDeviceActivationState";

	export const ENABLE_ORGANIZATION_LICENSE_SUMMARY = "web_organizationLicenseSummary";
	// export const LIVE_TRACKING = "liveTracking";
	export const DISABLE_GLOBAL_WORKSPACE_PICKER = "web_disableGlobalWorkspacePicker";
	export const ONE_MIN_TRACKING = "web_1minTracking";
	export const ENABLE_OTA_START_TRACKING = "web_startTracking";
	export const ENABLE_CUSTOM_MAP_LAYERS = "web_customMapLayers";
	export const ENABLE_CREATE_WAYPOINT = "web_createWaypoint";
	export const ENABLE_CREATE_SHAPE = "web_createShape";
	export const ENABLE_WAYPOINT_SYMBOLS = "web_waypointSymbols";
	export const SHOW_LEGACY_PROMPT = "web_showLegacy";
	export const ENABLE_MESSAGING_WORKSPACE_FILTER = "web_messagingWorkspaceFilter";
	export const ENABLE_WORKSPACE_QR_CODE = "web_workspaceQrCode";
	export const ENABLE_MAP_BETA = "web_mapBeta";
	export const ENABLE_TAK_SERVER = "web_takServer";
	export const ENABLE_ENTITY_STYLES = "web_entityStyles";
	export const ENABLE_MAP_WORKSPACE_MESSAGES = "web_mapWorkspaceMessages";
	export const ENABLE_ALTITUDE_OTA_SETTING = "web_altitudeOtaSetting";
	export const ENABLE_BACKHAUL_OTA_SETTING = "web_enableBackhaulOtaSetting";
	export const DISABLE_BATTERY_OTA_SETTING = "web_disableBatteryOtaSetting";
	export const ENABLE_STAFF_FEATURES = "web_enableStaffFeatures";
	export const ENABLE_DRONE_LAYER = "web_enableDroneLayer";
	export const ENABLE_FILE_SHARING = "web_enableFileSharing";
	export const ENABLE_TAK_BEAM = "web_enableTakBeam";
	export const CREATE_BEAM_AUTH = "web_createBeamAuth";
	export const SINGLE_WORKSPACE_TAK_SERVER = "web_singleWorkspaceTakServer";
	export const ENABLE_ORGANIZATION_ONBOARDING = "web_organizationOnboarding";
	export const ORGANIZATION_PASSWORD_RESET = "OrgPasswordReset";
	export const ENABLE_BIOMETRICS_VIEWS = "web_enableBiometricsViews";
	export const ENABLE_SELF_SERVE_API_KEYS = "web_enableSelfServeApiKeys";
	export const ENABLE_DELETE_WORKSPACE = "web_enableDeleteWorkspace";
	export const DISABLE_MAPBOX = "web_disableMapbox";

	export const features = [
		// DIY_WORKSPACE,
		DIY_ORGANIZATION,
		WORKSPACE_REMOVE_MEMBER,
		ORGANIZATION_REMOVE_MEMBER,
		ORGANIZATION_REMOVE_WORKSPACE,
		ORGANIZATION_JOIN_WORKSPACE,
		ORGANIZATION_LEAVE_WORKSPACE,
		ORGANIZATION_ADD_MEMBER,
		ORGANIZATION_ADD_WORKSPACE,
		ORGANIZATION_RESOURCES_TAB,
		ORGANIZATION_RENAME_MEMBER,
		ORGANIZATION_DEVICE_BORROWING_ON,
		ENABLE_ORGANIZATION_DEVICE_ACTIVATION_STATE,
		ENABLE_ORGANIZATION_LICENSE_SUMMARY,
		WORKSPACE_RESOURCES_TAB,
		WORKSPACE_RENAME_MEMBER,
		// WORKSPACE_ADD_MEMBER,
		ORGANIZATION_EDIT_BILLING,
		ORGANIZATION_DEVICE_USAGE_ON,
		// WORKSPACE_TRANSFER_DEVICE,
		// WORKSPACE_UNREGISTER_DEVICE,
		// REQUEST_WORKSPACE_KEY,
		// JOIN_WORKSPACE,
		// WORKSPACE_ASSIGN_DEVICE_TO_MEMBER,
		// CALLSIGN_CHANGES,
		// MAP_LAYERS,
		// LIVE_TRACKING,
		DISABLE_GLOBAL_WORKSPACE_PICKER,
		ONE_MIN_TRACKING,
		ENABLE_OTA_START_TRACKING,
		ENABLE_CUSTOM_MAP_LAYERS,
		ENABLE_CREATE_WAYPOINT,
		ENABLE_CREATE_SHAPE,
		ENABLE_WAYPOINT_SYMBOLS,
		SHOW_LEGACY_PROMPT,
		ENABLE_MESSAGING_WORKSPACE_FILTER,
		ORGANIZATION_DEVICE_DETAILS_ON,
		ENABLE_WORKSPACE_QR_CODE,
		ENABLE_MAP_BETA,
		ENABLE_TAK_SERVER,
		ENABLE_ENTITY_STYLES,
		ENABLE_MAP_WORKSPACE_MESSAGES,
		ENABLE_ALTITUDE_OTA_SETTING,
		DISABLE_BATTERY_OTA_SETTING,
		ENABLE_STAFF_FEATURES,
		ENABLE_DRONE_LAYER,
		ENABLE_FILE_SHARING,
		ENABLE_TAK_BEAM,
		CREATE_BEAM_AUTH,
		ENABLE_ORGANIZATION_ONBOARDING,
		SINGLE_WORKSPACE_TAK_SERVER,
		ORGANIZATION_PASSWORD_RESET,
		ENABLE_BACKHAUL_OTA_SETTING,
		ENABLE_BIOMETRICS_VIEWS,
		ENABLE_SELF_SERVE_API_KEYS,
		ENABLE_DELETE_WORKSPACE,
	];
}
