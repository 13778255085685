import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";

export interface CalloutProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof calloutVariants> {
  icon?: OverridableComponent<SvgIconTypeMap>;
}

const calloutVariants = cva(
  "flex items-center p-4 rounded-md gap-2",
  {
    variants: {
      variant: {
        default: "bg-gray-100",
        info: "bg-gray-100 border text-blue-400",
        destructive: "bg-destructive text-destructive-foreground disabled:bg-destructive/50 disabled:text-destructive-foreground hover:bg-destructive/90",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const Callout = React.forwardRef<HTMLDivElement, CalloutProps>(
  ({ className, icon: Icon, children, variant, ...props }, ref) => {
    return (
      <div
        className={cn(calloutVariants({
          variant,
          className
        }))}
        ref={ref}
        {...props}
      >
        {Icon && <Icon className="w-4 h-4 mr-2 flex-shrink-0" />}
        <div>{children}</div>
      </div>
    );
  }
);

Callout.displayName = "Callout";

export { Callout };
