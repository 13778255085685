import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";
import styled from "styled-components";

import { RenderIf } from "../../common/RenderIf";

export type SomewearDialogInfoBlockTypes = "info" | "warning" | "error" | "success";

interface IProps extends React.PropsWithChildren {
	type: SomewearDialogInfoBlockTypes;
	title: React.ReactNode;
	description?: React.ReactNode;
}

const icons: Record<SomewearDialogInfoBlockTypes, OverridableComponent<SvgIconTypeMap>> = {
	info: InfoIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	success: CheckCircleIcon,
};

export const SomewearDialogInfoBlock = (props: IProps) => {
	const Icon = icons[props.type];

	return (
		<Container>
			<Icon sx={{ fontSize: 60 }} />

			<div className="dialog-info-content">
				<div className="dialog-info-title">{props.title}</div>

				<RenderIf condition={Boolean(props.description)}>
					<div className="dialog-info-description">{props.description}</div>
				</RenderIf>

				<div>{props.children}</div>
			</div>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	padding: 4rem 2rem;

	.dialog-info-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		padding: 1rem;
		max-width: 400px;
	}

	.dialog-info-title {
		text-align: center;
	}

	.dialog-info-description {
		font-size: 14px;
		color: #7a8085;
		line-height: 1.5;
		margin-bottom: 2rem;
	}

	input {
		width: 400px;
		max-width: 100%;
	}
`;
