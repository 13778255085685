import { createAction } from "@reduxjs/toolkit";
import type { UpdateStyleSettingsRequest, UserIdentityResponse } from "@somewear/api";
import type { IIdentity } from "@somewear/model";
import { createActionSet } from "@somewear/model";

export const identityActions = {
	upsertIdentityList: createAction<IIdentity[]>("identity/list/upsert"),
	updateIdentityStyle: createActionSet<
		UpdateStyleSettingsRequest.AsObject,
		UserIdentityResponse.AsObject
	>("identity/style/update"),
};
