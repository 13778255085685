import type { LocationResponse, RouteResponse } from "@somewear/api";
import type { DateRange } from "@somewear/model";
import { createActionSet } from "@somewear/model";

export const trackingRouteActions = {
	getLive: createActionSet<void, RouteResponse.AsObject[]>("tracking/routes/get/live"),
	getLastKnown: createActionSet<undefined, RouteResponse.AsObject[]>(
		"tracking/routes/get/last_known"
	),
	getLocations: createActionSet<string[], LocationResponse.AsObject[]>(
		"tracking/route/get/locations",
		true
	),
	downloadLocations: createActionSet<string[], LocationResponse.AsObject[]>(
		"tracking/route/download/locations",
		true
	),
	getRoutes: createActionSet<DateRange, RouteResponse.AsObject[]>("tracking/route/get", true),
};
