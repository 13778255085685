import type { PayloadAction } from "@reduxjs/toolkit";
import type { GetRoutesResponse, LocationResponse, RouteResponse } from "@somewear/api";
import { GetRoutesRequest, IdentityRecord, IdentityType } from "@somewear/api";
import { contactsSlice, identityActions, selectContactEntities } from "@somewear/asset";
import { grpc, someGrpc } from "@somewear/grpc";
import type {
	ActionSetEpic,
	Contact,
	DateRange,
	IIdentity,
	IRequestPayload,
	IRequestPayloadAction,
	IResponsePayload,
	ITrackingRoute,
	VoidableRequestPayload,
} from "@somewear/model";
import {
	createActionSetEpicHandler,
	resetEpics,
	timestampFromMoment,
	timestampToMoment,
} from "@somewear/model";
import type { RootState } from "@web/app/rootReducer";
import moment from "moment";
import type { Epic, StateObservable } from "redux-observable";
import { combineEpics, ofType } from "redux-observable";
import { forkJoin, of } from "rxjs";
import { filter, map, mergeMap, switchMap, takeUntil } from "rxjs/operators";

import { selectGlobalDateFilter } from "../filters/filterSelectors";
import { selectSortedLocationsForGlobalDateRangeByUserId } from "../locations/locationSelectors";
import { trackingRouteActions } from "./trackingRouteActions";
import { selectTrackingRoutesById } from "./trackingRoutesSlice";

const getLiveRoutesEpic: ActionSetEpic<void, ITrackingRoute[]> = (action$, state$) => {
	return createActionSetEpicHandler(action$, state$, trackingRouteActions.getLive, () =>
		grpc.prepareRequest(someGrpc.getLiveRoutes).pipe(map((r) => r.toObject().routesList))
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const mapGetRoutesResponse = map<GetRoutesResponse, ITrackingRoute[]>((r) => {
	const routes = r.toObject().routesList;
	routes.forEach((route) => {
		if (route.location !== undefined) {
			route.location.userId = route.ownerId;
		}
	});
	return routes;
});

const getLastKnownRoutesEpic: ActionSetEpic<undefined, RouteResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, trackingRouteActions.getLastKnown, () =>
		grpc.prepareRequest(someGrpc.getLastKnownRoutes).pipe(mapGetRoutesResponse)
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const getRoutesEpic: ActionSetEpic<DateRange, ITrackingRoute[]> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingRouteActions.getRoutes,
		(payload) => {
			const request = new GetRoutesRequest();

			const from = moment(payload.data.start);
			const to = moment(payload.data.end);

			request.setFrom(timestampFromMoment(from));
			request.setTo(timestampFromMoment(to));

			return grpc
				.prepareRequestWithPayload(someGrpc.getRoutes, request)
				.pipe(mapGetRoutesResponse);
		},
		(payload) => {
			return {
				onFulfilled: "Successfully loaded routes",
				onPending: "Loading routes...",
				onRejected: "Failed to load routes",
			};
		}
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const getRouteLocationsEpic: ActionSetEpic<string[], LocationResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingRouteActions.getLocations,
		(payload) =>
			grpc
				.prepareRequestWithPayload(someGrpc.getRouteLocations, payload.data)
				.pipe(map((r) => r.toObject().locationsList)),
		(payload) => {
			return {
				onRejected: "An error occurred loading locations for the route.",
				onPending: "Loading locations for the route...",
				onFulfilled: "",
			};
		}
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const fetchLocationsAndGpx = (r: IRequestPayload<string[]>, state: RootState) => {
	const fileName = "Somewear tracking .gpx";

	const contacts = selectContactEntities(state);

	const routes = r.data.mapNotNull((routeId) => {
		const route = selectTrackingRoutesById(state, routeId);
		if (route === undefined) return undefined;

		const contact = contacts[route.ownerId];
		if (contact === undefined) return undefined;

		return Object.assign({}, route, { contact: contact });
	});

	const globalDateRange = selectGlobalDateFilter(state);

	const locations = Object.values(selectSortedLocationsForGlobalDateRangeByUserId(state) ?? [])
		.flatMap((loc) => loc)
		.mapNotNull((loc) => loc)
		.filter((loc) => r.data.includes(loc.routeId));

	return grpc
		.prepareRequestWithPayload(
			someGrpc.getRouteLocations,
			routes.map((route) => route.id)
		)
		.pipe(
			mergeMap((r) => {
				const allLocations = locations.concat(...r.toObject().locationsList);

				let filteredLocations = allLocations;
				if (globalDateRange !== undefined) {
					filteredLocations = allLocations.filter((location) => {
						return (
							timestampToMoment(location.timestamp!).valueOf() >=
								globalDateRange.start &&
							timestampToMoment(location.timestamp!).valueOf() <= globalDateRange.end
						);
					});
				}

				return forkJoin({
					locations: of(allLocations),
					file: grpc.prepareRequestWithPayload(someGrpc.downloadRouteLocations, {
						routes: routes,
						locations: filteredLocations,
					}),
				});
			}),
			map((r) => {
				console.log(r);
				const blob = new Blob([r.file.getData()], {
					type: "application/xml",
				});

				const objectUrl = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = objectUrl;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				a.remove();

				return r.locations;
			})
		);
};

const downloadRouteLocationsEpic: ActionSetEpic<string[], LocationResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingRouteActions.downloadLocations,
		(payload, state$: StateObservable<RootState>) =>
			of(payload).pipe(switchMap((r) => fetchLocationsAndGpx(r, state$.value))),
		(payload) => {
			return {
				onRejected: "Failed to download the GPX",
				onPending: "Downloading GPX",
				onFulfilled: "Successfully downloaded the GPX",
			};
		}
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const trackingRouteToIdentityEpic: Epic = (action$, state$) => {
	return action$.pipe(
		ofType(trackingRouteActions.getLastKnown.fulfilled),
		map((action: PayloadAction<IResponsePayload<RouteResponse.AsObject[]>>) => {
			return action.payload.data;
		}),
		map((routes) => {
			return routes.mapNotNull((route) => {
				if (route.owner === undefined) return undefined;

				let type: IdentityRecord.TypeMap[keyof IdentityRecord.TypeMap] | undefined;
				const account = route.owner;
				switch (account.type) {
					case IdentityType.USER:
						type = IdentityRecord.Type.USER;
						break;
					case IdentityType.RESOURCE:
						type = IdentityRecord.Type.RESOURCE;
						break;
					case IdentityType.DEVICE:
						type = IdentityRecord.Type.DEVICE;
						break;
					case IdentityType.INTEGRATION:
						type = IdentityRecord.Type.INTEGRATION;
						break;
				}

				const identity = {
					id: route.owner.identityId,
					fullName: route.owner.fullname,
					username: route.owner.username,
					type: type ?? IdentityRecord.Type.NONE,
				} as IIdentity;

				return identity;
			});
		}),
		map((identities) => {
			return identityActions.upsertIdentityList(identities);
		})
	);
};

const trackingRouteToContactEpic: Epic = (action$) => {
	return action$.pipe(
		ofType(trackingRouteActions.getLastKnown.fulfilled),
		map((action: PayloadAction<IResponsePayload<RouteResponse.AsObject[]>>) => {
			return action.payload.data;
		}),
		map((routes) => {
			const contacts = routes.mapNotNull((route) => {
				if (route.owner === undefined) return;
				// set the conversation to user relation
				const userId = route.ownerId;

				// if the interval contact does not have an email or a phone number they are likely from a public track
				const contact: Contact = {
					id: userId,
					name: route.owner.fullname,
					email: route.owner.email,
					username: route.owner.username,
					phone: route.owner.phonenumber,
					// displayName: getDisplayNameForWorkspaceAsset(route.owner!),
					type: route.owner.type,
					identityId: route.owner.identityId,
				};
				return contact;
			});

			return contactsSlice.actions.upsertContacts(contacts);
		})
	);
};

export default combineEpics<
	IRequestPayloadAction<VoidableRequestPayload>,
	IRequestPayloadAction<unknown>,
	RootState
>(
	getLiveRoutesEpic,
	getLastKnownRoutesEpic,
	getRouteLocationsEpic,
	downloadRouteLocationsEpic,
	getRoutesEpic,
	trackingRouteToIdentityEpic,
	trackingRouteToContactEpic
);
