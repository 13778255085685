import type { Dictionary } from "@reduxjs/toolkit";
import { IdentityRecord, IdentityType, WorkspaceRole } from "@somewear/api";
import {
	selectActiveIdentityId,
	selectActiveUserAccountId,
	selectActiveWorkspaceId,
} from "@somewear/auth";
import type {
	IdentityRecordType,
	IntegrationWorkspaceConfig,
	IWorkspaceAsset,
} from "@somewear/model";
import { getDictionaryValue, selectEcho } from "@somewear/model";
import _ from "lodash";
import { createSelector } from "reselect";

import { selectIdentityEntities } from "../identity";
import type { IWorkspaceIntegrationAsset } from "./asset.slice";
import {
	selectAllWorkspaceAssets,
	selectWorkspaceAssetById,
	selectWorkspaceAssetEntities,
} from "./asset.slice";
import type { AssetsState } from "./assetsState";

export const selectUnarchivedWorkspaceAssets = createSelector(
	[selectAllWorkspaceAssets],
	(assets) => {
		return assets.filter((it) => !it.isArchived);
	}
);

export const selectVisibilityOverrides = createSelector(
	[selectUnarchivedWorkspaceAssets],
	(assets) => {
		const visibilityOverrides: Dictionary<boolean> = {};
		assets.forEach((asset) => {
			visibilityOverrides[asset.id] = asset.visibilityOverride;
		});
		return visibilityOverrides;
	}
);

export const selectHasAssetVisibilityOverrides = createSelector(
	[selectVisibilityOverrides],
	(visibilityOverrides) => {
		return (
			Object.values(visibilityOverrides).find((value) => value !== undefined) !== undefined
		);
	}
);

export const selectIdentityIdByAssetId = createSelector([selectAllWorkspaceAssets], (assets) => {
	const identityIdDict: Dictionary<string> = {};
	assets.forEach((asset) => {
		identityIdDict[asset.id] = asset.identityId;
	});
	return identityIdDict;
});

export const selectIdentityIdForAssetId = createSelector(
	[selectIdentityIdByAssetId, selectEcho<string | undefined, AssetsState>],
	(identityDict, assetId) => {
		return getDictionaryValue(identityDict, assetId);
	}
);

export const selectAllWorkspaceAssetsWithTypeAndExternalId = createSelector(
	[selectAllWorkspaceAssets, selectIdentityEntities],
	(assets, identityDict) => {
		return assets.map((asset) => {
			const _asset = _.cloneDeep(asset);
			const identity = identityDict[asset.identityId];
			if (identity !== undefined) {
				// correct information for archived assets
				_asset.fullname = identity.fullName;
				_asset.email = identity.email;
				switch (identity.type) {
					case IdentityRecord.Type.USER:
						_asset.type = IdentityType.USER;
						break;
					case IdentityRecord.Type.RESOURCE:
						_asset.type = IdentityType.RESOURCE;
						break;
					case IdentityRecord.Type.DEVICE:
						_asset.type = IdentityType.DEVICE;
						break;
					case IdentityRecord.Type.INTEGRATION:
						_asset.type = IdentityType.INTEGRATION;
						break;
				}
			}
			_asset.externalId = identity?.externalId;
			return _asset;
		});
	}
);

export const selectTakWorkspaceResources = createSelector(
	[selectAllWorkspaceAssetsWithTypeAndExternalId],
	(assets) =>
		assets.filter(
			(it) => it.type === IdentityType.RESOURCE && it.externalId?.startsWith("tak:")
		)
);

export const selectWorkspaceAssetsWithTypeForType = createSelector(
	[
		selectAllWorkspaceAssetsWithTypeAndExternalId,
		selectEcho<IdentityRecordType | undefined, AssetsState>,
	],
	(assets, type) => {
		return assets.filter((asset) => asset.type === type);
	}
);

export const selectWorkspaceAssetsWithTypeForWorkspaceAndType = createSelector(
	[selectWorkspaceAssetsWithTypeForType, selectEcho<string | undefined, AssetsState>],
	(assets, workspaceId) => {
		return assets.filter((asset) => asset.workspaceId === workspaceId);
	}
);

export const selectWorkspaceAssetsWithTypeForActiveWorkspace = createSelector(
	[selectAllWorkspaceAssetsWithTypeAndExternalId, selectActiveWorkspaceId],
	(assets, workspaceId) => {
		return assets.filter((asset) => asset.workspaceId === workspaceId);
	}
);

export const selectUnarchivedWorkspaceIntegrations = createSelector(
	[selectWorkspaceAssetsWithTypeForActiveWorkspace],
	(members) => {
		return members.filter((user) => user.type === IdentityType.INTEGRATION && !user.isArchived);
	}
);

export const selectActiveWorkspaceAssetEntitiesForActiveWorkspace = createSelector(
	[selectWorkspaceAssetsWithTypeForActiveWorkspace],
	(assets) => {
		const assetDict: Dictionary<IWorkspaceAsset> = {};
		assets.forEach((it) => {
			assetDict[it.id] = it;
		});
		return assetDict;
	}
);

export const selectActiveWorkspaceAssetById = createSelector(
	[selectActiveWorkspaceAssetEntitiesForActiveWorkspace, selectWorkspaceAssetById],
	(assetDict, asset) => {
		if (asset === undefined) return undefined;
		return assetDict[asset.id];
	}
);

export const selectWorkspaceAssetForActiveUser = createSelector(
	[selectWorkspaceAssetEntities, selectActiveUserAccountId],
	(assetDict, activeUserAccountId) => {
		if (activeUserAccountId === undefined) return undefined;
		return assetDict[activeUserAccountId];
	}
);

export const selectIsWorkspaceAdmin = createSelector(
	[selectWorkspaceAssetForActiveUser],
	(asset) => {
		return asset !== undefined && asset.workspaceRole === WorkspaceRole.WORKSPACEROLEADMIN;
	}
);

export const selectWorkspaceIdsForIdentityId = createSelector(
	[selectAllWorkspaceAssets, selectEcho<string, AssetsState>],
	(assets, identityId) => {
		return assets
			.filter((it) => it.identityId === identityId)
			.mapNotNull((it) => it.workspaceId);
	}
);

export const selectWorkspaceAssetsForActiveIdentity = createSelector(
	[selectAllWorkspaceAssets, selectActiveIdentityId],
	(assets, activeIdentityId) => {
		if (activeIdentityId === undefined) return undefined;
		return assets.filter((it) => it.identityId === activeIdentityId);
	}
);

export const selectAdminAccountsForActiveIdentity = createSelector(
	[selectWorkspaceAssetsForActiveIdentity],
	(assets) => {
		return assets?.filter((it) => it.workspaceRole === WorkspaceRole.WORKSPACEROLEADMIN);
	}
);

export const selectAdministersWorkspaces = createSelector(
	[selectAdminAccountsForActiveIdentity],
	(assets) => {
		if (assets === undefined) return false;
		return assets.isNotEmpty();
	}
);

export const selectAccountsForIntegration = createSelector(
	[selectUnarchivedWorkspaceAssets, selectEcho<string, AssetsState>],
	(assets, integrationIdentityId) => {
		return assets.filter((it) => it.identityId === integrationIdentityId);
	}
);

export const selectInboundWorkspaceConfigsForIntegration = createSelector(
	[selectAccountsForIntegration],
	(assets) => {
		return assets.map((it) => {
			return {
				workspaceId: it.workspaceId,
				enabled: (it as IWorkspaceIntegrationAsset).inboundEnabled,
			} as IntegrationWorkspaceConfig;
		});
	}
);

export const selectOutboundWorkspacesForIntegration = createSelector(
	[selectAccountsForIntegration],
	(assets) => {
		return assets.map((it) => it.workspaceId);
	}
);

export const selectOutboundWorkspaceConfigsForIntegration = createSelector(
	[selectAccountsForIntegration],
	(assets) => {
		return assets.map((it) => {
			return {
				workspaceId: it.workspaceId,
				enabled: (it as IWorkspaceIntegrationAsset).outboundEnabled,
			} as IntegrationWorkspaceConfig;
		});
	}
);
