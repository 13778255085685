import { createAction } from "@reduxjs/toolkit";
import type {
	PongResponse,
	SendPingRequest,
	SignInRequest,
	SignInResponse,
	UpdateStyleSettingsRequest,
	UpdateUserPasswordResponse,
	WorkspaceResponse,
} from "@somewear/api";
import type { UpdatePasswordArgs } from "@somewear/auth";
import { createActionSet } from "@somewear/model";
import type { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

export const emitActiveWorkspaceChange = createAction<WorkspaceResponse.AsObject>(
	"app/activeWorkspace/change"
);

export const appActions = {
	signIn: createActionSet<SignInRequest.AsObject, SignInResponse.AsObject>("app/signIn"),
	signUp: createActionSet<SignInRequest.AsObject, SignInResponse.AsObject>("app/signUp"),
	updatePassword: createActionSet<UpdatePasswordArgs, UpdateUserPasswordResponse.AsObject>(
		"app/updatePassword"
	),
	updateWorkspaceStyle: createActionSet<
		UpdateStyleSettingsRequest.AsObject,
		WorkspaceResponse.AsObject
	>("workspace/style/update"),
	sendPing: createActionSet<Timestamp.AsObject, SendPingRequest.AsObject>("app/event/ping"),
	receivePongEvent: createAction<PongResponse.AsObject>("app/event/receive"),
};
