import type { PayloadAction } from "@reduxjs/toolkit";
import type { MapLayer, MapLayers } from "@somewear/layers";
import {
	_selectAllMapLayers,
	fetchNetworkLink$,
	mapLayerFactory,
	mapLayersActions,
	mapLayersSlice,
	MapLayersStore,
	MapLayerType,
	upsertMapLayers,
} from "@somewear/layers";
import type { ActionSetEpic, IRequestPayload } from "@somewear/model";
import { createActionSetEpicHandler, resetEpics } from "@somewear/model";
import type { Action } from "redux";
import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import type { Observable } from "rxjs";
import { concat, from, of } from "rxjs";
import { filter, map, mergeMap, switchMap, takeUntil, tap } from "rxjs/operators";
import { v4 as uuid } from "uuid";

import type { LayerState } from "./layer.state";

const initiateNetworkLinkFetchingEpic: Epic<Action<MapLayers>, Action> = (action$, state$) => {
	return action$.pipe(
		filter(mapLayersSlice.actions.upsertMapLayers.match),
		mergeMap((action) => {
			return action.payload.mapNotNull((layer) => {
				if (layer.srcLink === undefined || layer.type === MapLayerType.Raster)
					return undefined;

				return mapLayersActions.fetch.request({ data: layer, noTimeout: true });
			});
		}),
		takeUntil(action$.pipe(filter(resetEpics.match)))
	);
};

const addMapLayerEpic: Epic<Action, PayloadAction<MapLayers>> = (action$, state$) => {
	return action$.pipe(
		filter(mapLayersActions.add.request.match),
		map((action) => {
			const rootState = state$.value as LayerState;
			const newLayerId = rootState.mapLayers.newLayerId;
			const actionsOut: PayloadAction<MapLayers>[] = [];
			if (newLayerId) {
				const _layer = _selectAllMapLayers(rootState).find((_layer) => {
					return _layer.id === newLayerId;
				});

				if (_layer !== undefined) {
					// remove the temporary layer and add the layer with data
					// adapter.removeOne(state.layers, getMapLayerKey(_layer));
					const layer = mapLayerFactory(_layer, action.payload?.data, 0);
					actionsOut.push(upsertMapLayers([layer]));

					// MapLayersStore.addLayerData(layer, action.payload.data);
				} else {
					console.error("This layer doesn't exist");
				}
				// updateStore(state);
				// actionsOut.push(clearNewLayerId());
			} else {
				const layerId = uuid();
				// const layerId = action.payload.link?.href ?? uuid();

				const _layer: MapLayer = {
					id: layerId,
					fileName: layerId,
					index: 0,
					order: -1,
					state: {
						loading: false,
						visible: true,
					},
					type: MapLayerType.Kml,
				};
				const layer = mapLayerFactory(_layer, action.payload?.data, 0);
				actionsOut.push(upsertMapLayers([layer]));
			}

			return actionsOut;
		}),
		mergeMap((action) => from(action)),
		tap((result) => console.log(result)),
		takeUntil(action$.pipe(filter(resetEpics.match)))
	);
};

const fetchNetworkLinkEpic: ActionSetEpic<MapLayer, MapLayer> = (action$, state$) => {
	const requestBuilder = (
		payload: IRequestPayload<MapLayer>
	): Observable<MapLayer | undefined> => {
		return fetchNetworkLink$(payload.data);
	};

	return createActionSetEpicHandler(action$, state$, mapLayersActions.fetch, requestBuilder).pipe(
		takeUntil(action$.pipe(filter(resetEpics.match)))
	);
};

const initMapLayersEpic: Epic<Action, PayloadAction<MapLayers | IRequestPayload<MapLayer>>> = (
	action$
) =>
	action$.pipe(
		filter(mapLayersSlice.actions.init.match),
		switchMap(() => {
			return MapLayersStore.read();
		}),
		mergeMap((layers) => {
			if (layers === undefined) return of(upsertMapLayers([]));

			return concat(
				from(layers).pipe(
					filter((layer) => layer.links !== undefined || layer.srcLink !== undefined),
					map((layer) => {
						return mapLayersActions.fetch.request({
							data: layer,
							noTimeout: true,
						});
					})
				),
				of(upsertMapLayers(layers))
			);
		}),
		takeUntil(action$.pipe(filter(resetEpics.match)))
	);

export const mapLayerEpics = combineEpics<any, any, any>(
	initMapLayersEpic,
	fetchNetworkLinkEpic,
	addMapLayerEpic,
	initiateNetworkLinkFetchingEpic
);
