import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { type HealthActivityResponse } from "@somewear/api";
import { emitAssetAccountsDeleted } from "@somewear/asset";
import type { IError } from "@somewear/model";

import type { BiometricState } from "./biometric.state";

export interface IBiometrics extends HealthActivityResponse.AsObject {
	ownerId: string;
}

const adapter = createEntityAdapter<IBiometrics>({
	selectId: (event) => event.ownerId,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllBiometrics,
	selectById: selectBiometricsById,
	selectEntities: selectBiometricEntities,
} = adapter.getSelectors((state: BiometricState) => state.biometrics);

export const biometricsSlice = createSlice({
	name: "biometrics",
	initialState: adapter.getInitialState(),
	reducers: {
		apiHealthActivityRequest() {},
		apiHealthActivitySuccess(state, action: PayloadAction<IBiometrics[]>) {
			action.payload.forEach((event) => {
				const prevEvent = adapter.getSelectors().selectById(state, event.ownerId);
				if (prevEvent?.timestamp !== undefined) {
					if (event.timestamp!.seconds > prevEvent.timestamp.seconds) {
						adapter.upsertOne(state, event);
					}
				} else {
					adapter.upsertOne(state, event);
				}
			});

			/*action.payload.forEach((activity) => {

				if (activity.owner === undefined && state.selfUserId === undefined) {
					return;
				}

				let userId = activity.owner?.id ?? state.selfUserId!;
				if (!(userId in state.healthActivity)) {
					state.healthActivity[userId] = {
						data: {},
						loaded: true,
					};
				}

				state.healthActivity[userId].data[activity.id] = activity;
				state.healthActivity[userId].loaded = true;
			});*/
		},
		apiHealthActivityError(state, action: PayloadAction<IError>) {},
	},
	extraReducers: (builder) => {
		builder.addCase(emitAssetAccountsDeleted, (state, action) => {
			// when an account is deleted, delete the related data
			const deletedAccountIds = action.payload.map((it) => it.id);
			const deletedItems = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => deletedAccountIds.includes(it.ownerId));
			adapter.removeMany(
				state,
				deletedItems.map((it) => it.id)
			);
		});
	},
});

export const { apiHealthActivityRequest, apiHealthActivitySuccess, apiHealthActivityError } =
	biometricsSlice.actions;
