import type { Dictionary } from "@reduxjs/toolkit";
import type { LocationResponse } from "@somewear/api";
import {
	AssetStateHelpers,
	selectContactById,
	selectContactTypeMap,
	selectIdentityEntities,
	selectIdentityIdByAssetId,
	selectUnarchivedContacts,
} from "@somewear/asset";
import { selectAllBiometrics } from "@somewear/biometric";
import type { IContactForDisplay } from "@somewear/model";
import { AssetState, getDictionaryValue, selectEcho, timestampToMoment } from "@somewear/model";
import type { IOrganization } from "@somewear/organization";
import { selectOrganizationEntities } from "@somewear/organization";
import { selectActiveSosUsers } from "@somewear/sos";
import type { IWorkspace } from "@somewear/workspace";
import {
	PERSONAL_WORKSPACE_ID,
	selectHasTeamWorkspaces,
	selectWorkspaceEntities,
	WorkspaceUtil,
} from "@somewear/workspace";
import type { Units } from "@turf/turf";
import { center as turfCenter, distance as turfDistance, length as turfLength } from "@turf/turf";
import { selectContactHasDeviceMap } from "@web/app/contact/contact.selectors";
import type { RootState } from "@web/app/rootReducer";
import { selectAllContactsWithWorkspace } from "@web/app/tracking.contact.selectors";
import { calculateUserState, sortByTimestamp } from "@web/common/utils";
import type { MostRecentLocation, UserStates } from "@web/tracking/trackingSelectors";
import {
	selectActiveTrackingUser,
	selectActiveTrackingUserId,
	selectFocusedTrackingUserId,
	selectIsCleanTracksEnabled,
	selectIsStaleDisabled,
	selectTrackingFilters,
	selectTrackingSelectedLocationId,
	selectUseMetric,
	selectWorkspaceFilters,
} from "@web/tracking/trackingSelectors";
import {
	ACTIVE_TRACKING_TIMEOUT,
	BIOMETRIC_TIMEOUT_IN_HOURS,
	DangerZone,
	PreviewLocationCount,
	WarningZone,
} from "@web/tracking/trackingSlice";
import type { Feature, Point } from "geojson";
import type { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import { selectGlobalDateFilter, selectIsHiddenByUser } from "../filters/filterSelectors";
import { selectLastKnownTrackingRouteIdsForDateRangeByUserId } from "../routes/trackingRouteSelectors";
import { selectAllLocations } from "./location.slice";

const CLEAN_MIN_DISTANCE_IN_METERS = 50;
export interface ProcessedLocation extends LocationResponse.AsObject {
	exitTimestamp?: Timestamp.AsObject;
	exploded?: boolean;
	virtual?: boolean;
}

export interface IWorkspaceEntity extends IWorkspace {
	organization?: IOrganization;
	assetCount?: number;
	visibleAssetCount?: number;
}

function isVirtual(location: ProcessedLocation): location is VirtualLocation {
	return location.virtual === true && location.exploded !== undefined;
}

export interface VirtualLocation extends ProcessedLocation {
	exploded: boolean;
	virtual: true;
}

export const selectLiveTracking = (state: RootState): boolean => {
	return state.tracking.liveTracking;
};

const selectLocationsByUserId = createSelector([selectAllLocations], (locations) => {
	const locationDict: Dictionary<LocationResponse.AsObject[]> = {};
	locations.forEach((location) => {
		locationDict[location.userId] = locationDict[location.userId] ?? [];
		locationDict[location.userId] = locationDict[location.userId]!.concat(location);
	});
	return locationDict;
});

const selectLocationsForUser = createSelector(
	[selectLocationsByUserId, selectEcho<string, RootState>],
	(locationsByUserId, userId) => {
		if (locationsByUserId === undefined) return undefined;
		if (locationsByUserId === null) return null;
		return locationsByUserId[userId];
	}
);

export const hasTrackingSelector = createSelector(
	[selectLocationsForUser],
	(locations) => locations !== undefined
);

export const updateTimestampSelector = (state: RootState) => {
	return state.tracking.updateTimestamp;
};

export const expandedUsersSelector = (state: RootState) => {
	return state.tracking.expandedUsers;
};

/*const selectAllLocations = createSelector([selectLocationsByUserId], (locationsByUserId) => {
	if (locationsByUserId === undefined) return undefined;
	if (locationsByUserId === null) return null;
	return Object.values(locationsByUserId).flatMap((locations) => {
		return locations as LocationResponse.AsObject[];
	});
});*/
/*
const selectAllSortedLocations = createSelector([selectAllLocations], (locations) => {
	if (locations === undefined) return undefined;
	if (locations === null) return null;
	return [...locations].sort(sortByTimestamp);
});
*/

export const selectMostRecentLocationByIdentityId = createSelector(
	[selectAllLocations, selectIdentityIdByAssetId],
	(locations, identityIdDict) => {
		if (locations === undefined) return undefined;
		if (locations === null) return null;
		const locationsDesc = locations.reverse();
		const locationDict: Dictionary<LocationResponse.AsObject> = {};
		locationsDesc.forEach((location) => {
			const identityId = identityIdDict[location.userId];
			if (identityId !== undefined && locationDict[identityId] === undefined) {
				locationDict[identityId] = location;
			}
		});
		return locationDict;
	}
);

export const selectSortedLocationsForGlobalDateRangeByUserId = createSelector(
	[
		selectAllLocations,
		selectGlobalDateFilter,
		selectIsCleanTracksEnabled,
		selectTrackingSelectedLocationId,
	],
	(locations, globalDateRange, cleanTracks, selectedLocationId) => {
		if (locations === undefined) return undefined;
		if (locations === null) return null;

		let locationsFilteredByGlobalDateRange = locations;
		if (globalDateRange !== undefined) {
			locationsFilteredByGlobalDateRange = locations.filter((location) => {
				return (
					timestampToMoment(location.timestamp!).valueOf() >= globalDateRange.start &&
					timestampToMoment(location.timestamp!).valueOf() <= globalDateRange.end
				);
			});
		}

		const locationsByUserId: Dictionary<ProcessedLocation[]> = {};
		locationsFilteredByGlobalDateRange.forEach((location) => {
			if (locationsByUserId[location.userId] === undefined)
				locationsByUserId[location.userId] = [];
			locationsByUserId[location.userId]!.unshift(location);
		});

		// if clean tracks is disabled, don't do any further processing
		if (!cleanTracks) return locationsByUserId;

		Object.keys(locationsByUserId).forEach((userId) => {
			const locationsForUser = locationsByUserId[userId];
			if (locationsForUser === undefined || locationsForUser.isEmpty()) return;

			// No collapsing will occur
			if (locationsForUser.length < 4) return;

			const nodes = getNodeList(locationsForUser);

			const processedLocations = collapseNodes(
				nodes.last(),
				nodes.first(),
				selectedLocationId
			);

			// update the locations for the user, reversing the list to preserve the expected sort from tail -> head
			locationsByUserId[userId] = [...processedLocations].reverse();
		});

		return locationsByUserId;
	}
);

export const selectProcessedLocationsById = createSelector(
	[selectSortedLocationsForGlobalDateRangeByUserId],
	(locationsByUserId) => {
		if (locationsByUserId === undefined) return undefined;
		if (locationsByUserId === null) return null;
		const allLocations = Object.values(locationsByUserId)
			.flatMap((it) => it)
			.mapNotNull((it) => it);
		const locationsById: Dictionary<ProcessedLocation> = {};
		allLocations.forEach((location) => {
			locationsById[location.id] = location;
		});
		return locationsById;
	}
);

export const selectProcessedLocationById = createSelector(
	[selectProcessedLocationsById, selectEcho<string | undefined, RootState>],
	(locationsById, id) => {
		if (locationsById === undefined || id === undefined) return undefined;
		if (locationsById === null) return null;
		return getDictionaryValue(locationsById, id);
	}
);

export const selectMetricsForLastKnownRouteForActiveUser = createSelector(
	[
		selectGlobalDateFilter,
		selectSortedLocationsForGlobalDateRangeByUserId,
		selectLastKnownTrackingRouteIdsForDateRangeByUserId,
		selectActiveTrackingUserId,
		selectUseMetric,
	],
	(globalDateFilter, locationsByUserId, routeIdsByUserId, userId, useMetric) => {
		if (globalDateFilter !== undefined) return undefined;
		if (locationsByUserId === undefined || userId === undefined) return undefined;
		if (locationsByUserId === null) return null;
		const routeId = routeIdsByUserId[userId];
		const lastKnownRouteLocations =
			locationsByUserId[userId]?.filter((location) => location.routeId === routeId) ?? [];

		if (lastKnownRouteLocations.length < 2) return undefined;

		const coordinates = lastKnownRouteLocations.map((location) => {
			return [location.coordinate!.longitude, location.coordinate!.latitude];
		});
		const moments = lastKnownRouteLocations.map((location) =>
			timestampToMoment(location.timestamp!)
		);
		const units: Units = useMetric ? "kilometers" : "miles";

		const distance = turfLength(
			{
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "LineString",
							coordinates: coordinates,
						},
						properties: {},
					},
				],
			},
			{ units }
		);

		const time = Math.abs(moments.last().diff(moments.first())) / 1000;
		const speed = distance / (time / 3600);

		const splitDistance = turfLength(
			{
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "LineString",
							coordinates: [coordinates.first(), coordinates.second()],
						},
						properties: {},
					},
				],
			},
			{ units }
		);

		const ascMoments = moments.reverse();

		const splitTime = Math.abs(ascMoments.first().diff(moments.second())) / 1000;
		const splitSpeed = splitDistance / (splitTime / 3600);

		return {
			distance: Math.round(distance * 10) / 10,
			time: Math.round(time / 60),
			avgSpeed: Math.round(speed),
			latestSpeed: Math.round(splitSpeed),
			lastUpdated: moments.last(),
		};
	}
);

export const selectLocationsForMap = createSelector(
	[selectSortedLocationsForGlobalDateRangeByUserId, expandedUsersSelector, selectIsHiddenByUser],
	(locationsByUserId, expandedUsers, hiddenUserDict) => {
		if (locationsByUserId === undefined) return undefined;
		if (locationsByUserId === null) return null;

		const filteredLocationsByUserId: Dictionary<ProcessedLocation[]> = {};

		Object.values(locationsByUserId)
			.mapNotNull((locations) => locations)
			.forEach((locations) => {
				const userId = locations.firstOrUndefined()?.userId;
				if (userId !== undefined && hiddenUserDict[userId]) return;

				locations.forEach((location) => {
					const userId = location.userId;
					if (filteredLocationsByUserId[userId] === undefined)
						filteredLocationsByUserId[userId] = [];
					const locationCount = filteredLocationsByUserId[userId]!.length;
					if (!expandedUsers[userId] && locationCount < PreviewLocationCount) {
						filteredLocationsByUserId[userId]!.unshift(location);
					} else if (expandedUsers[userId]) {
						filteredLocationsByUserId[userId]!.unshift(location);
					} else {
						console.log(`expand to see more locations`);
					}
				});
			});

		return filteredLocationsByUserId;
	}
);

export const selectMostRecentLocationsForDateRangeByUserId = createSelector(
	[selectSortedLocationsForGlobalDateRangeByUserId],
	(locationsByUserId) => {
		if (locationsByUserId === undefined) return undefined;
		if (locationsByUserId === null) return null;
		const filteredLocationsByUserId: Dictionary<LocationResponse.AsObject> = {};
		Object.keys(locationsByUserId).forEach((userId) => {
			filteredLocationsByUserId[userId] = locationsByUserId[userId]?.first();
		});
		return filteredLocationsByUserId;
	}
);

const selectMostRecentTimestampsByUserId = createSelector(
	[selectMostRecentLocationsForDateRangeByUserId],
	(mostRecentLocationsByUserId) => {
		if (mostRecentLocationsByUserId === undefined) return undefined;
		if (mostRecentLocationsByUserId === null) return null;

		const mostRecentTimestampsByUserId: Dictionary<Timestamp.AsObject> = {};
		Object.keys(mostRecentLocationsByUserId).forEach((userId) => {
			mostRecentTimestampsByUserId[userId] = mostRecentLocationsByUserId[userId]?.timestamp;
		});
		return mostRecentTimestampsByUserId;
	}
);

const selectIsGlobalDateFilterHistorical = createSelector(
	[selectGlobalDateFilter],
	(globalDateRange) => {
		if (globalDateRange === undefined) return false;
		return moment(new Date()).valueOf() > globalDateRange.end;
	}
);

export const userStatesSelector = createSelector(
	[
		selectMostRecentTimestampsByUserId,
		updateTimestampSelector,
		selectHasTeamWorkspaces,
		selectActiveSosUsers,
		selectAllBiometrics,
		selectContactHasDeviceMap,
		selectIsGlobalDateFilterHistorical,
		selectIsStaleDisabled,
	],
	(
		mostRecentTimestamps,
		timer,
		hasTeamWorkspaces,
		sosUsers,
		biometrics,
		assetHasDeviceMap,
		isHistorical,
		disableStale
	) => {
		if (mostRecentTimestamps === undefined) return undefined;
		if (mostRecentTimestamps === null) return null;
		const userStates: UserStates = {
			states: {},
			timer: { timeout: 0, timestamp: 0 },
		};

		if (!hasTeamWorkspaces) {
			return userStates;
		}

		// console.log("running userStatesSelector");
		const now = moment();
		let timeout = -1;
		Object.keys(mostRecentTimestamps).forEach((userId) => {
			const timestamp = moment(mostRecentTimestamps[userId]!.seconds * 1000);
			let userState: AssetState;
			if (sosUsers.has(userId)) {
				userState = AssetState.Sos;
			} else {
				/* else if (!Boolean(assetHasDeviceMap[userId])) {
				userState = AssetState.Unassigned;
			} */
				// force active if the global date filter is historical or if the override setting is enabled
				userState = calculateUserState(timestamp, isHistorical || disableStale);

				const metric = biometrics.find((metric) => metric.ownerId === userId);

				const now = moment();
				if (
					metric?.timestamp !== undefined &&
					timestampToMoment(metric.timestamp).isBefore(
						now.subtract(BIOMETRIC_TIMEOUT_IN_HOURS, "hours")
					)
				) {
					// this biometric is expired, do not affect the asset state
				} else if (metric?.coreTemperature !== undefined) {
					if (metric.coreTemperature >= DangerZone) {
						userState = AssetState.Danger;
					} else if (metric.coreTemperature >= WarningZone) {
						userState = AssetState.Warning;
					}
				}
			}
			userStates.states[userId] = userState;
			// calculate the closest timeout to update the states
			if (userState === AssetState.Active) {
				const before = now.subtract(ACTIVE_TRACKING_TIMEOUT, "minutes");
				const diff = timestamp.diff(before);
				if (diff < timeout || timeout === -1) {
					timeout = diff;
				}
			} else if (userState === AssetState.Stale) {
				const before = now.subtract(1, "hour");
				const diff = timestamp.diff(before);
				if (diff < timeout || timeout === -1) {
					timeout = diff;
				}
			}
		});
		if (timeout > 0) {
			userStates.timer = { timeout: timeout, timestamp: now.valueOf() };
		}
		return userStates;
	}
);

export const selectStateById = createSelector(
	[userStatesSelector, selectContactById],
	(model, contact) => {
		if (model === undefined) return undefined;
		if (model === null) return null;
		if (userStatesSelector === null) return null;
		return model.states[contact?.id ?? ""];
	}
);

export const selectMostRecentLocationsForDateRangeAndNotFilteredByUserId = createSelector(
	[
		selectMostRecentLocationsForDateRangeByUserId,
		userStatesSelector,
		selectContactHasDeviceMap,
		selectContactTypeMap,
		selectTrackingFilters,
		selectLiveTracking,
		selectActiveTrackingUser,
		// hiddenUsersSelector,
		// shownUsersSelector,
	],
	(
		locationsByUserId,
		assetStates,
		hasDeviceMap,
		assetTypeMap,
		trackingFilters,
		isLiveTracking,
		activeTrackingUser
		// hiddenUsers,
		// shownUsers
	) => {
		if (locationsByUserId === undefined || trackingFilters === undefined) return undefined;
		if (locationsByUserId === null) return null;

		const filteredLocationsByUserId: Dictionary<LocationResponse.AsObject> = {};
		Object.keys(locationsByUserId).forEach((userId) => {
			const assetState = assetStates?.states[userId] ?? AssetState.Active;
			const hasDevice = hasDeviceMap[userId] === true;
			const isVisible = AssetStateHelpers.isUserVisible(
				userId,
				assetState,
				hasDevice,
				trackingFilters,
				isLiveTracking,
				[],
				[],
				assetTypeMap[userId],
				locationsByUserId[userId]
			);
			if (activeTrackingUser?.userId === userId || isVisible) {
				filteredLocationsByUserId[userId] = locationsByUserId[userId];
			}
		});
		return filteredLocationsByUserId;
	}
);

const distinctByIdentity = (mostRecentLocationsForMap: MostRecentLocation[]) => {
	const locationsByIdentityId: Dictionary<MostRecentLocation> = {};
	mostRecentLocationsForMap.forEach((mostRecentLocation) => {
		const location = mostRecentLocation.location;
		const identityId = mostRecentLocation.contact?.identityId;
		if (identityId !== undefined) {
			if (locationsByIdentityId[identityId] === undefined) {
				locationsByIdentityId[identityId] = mostRecentLocation;
			} else if (
				timestampToMoment(locationsByIdentityId[identityId]!.location!.timestamp!).isBefore(
					timestampToMoment(location!.timestamp!)
				)
			) {
				locationsByIdentityId[identityId] = mostRecentLocation;
			}
		} else {
			console.error("for some reason the identity id is undefined");
		}
	});
	return locationsByIdentityId;
};

export const selectMostRecentLocationsForWorkspaceFilter = createSelector(
	[
		selectMostRecentLocationsForDateRangeAndNotFilteredByUserId,
		selectAllContactsWithWorkspace,
		selectTrackingFilters,
		userStatesSelector,
		selectIdentityEntities,
	],
	(locations, contacts, trackingFilters, userStates, identityDict) => {
		if (locations === null) return null;
		if (locations === undefined || userStates === undefined || trackingFilters === undefined)
			return undefined;

		const mostRecentLocations = Object.values(locations).mapNotNull<MostRecentLocation>(
			(location) => {
				if (location === undefined) return undefined;
				const contact = contacts.find((contact) => contact.id === location.userId);
				if (contact?.identityId === undefined) return undefined;
				const identity = identityDict[contact.identityId];
				if (identity === undefined) return undefined;
				return {
					location: location,
					contact: contact,
					assetState: userStates?.states[location.userId] ?? AssetState.Active,
					identity: identity,
				};
			}
		);

		let unsortedLocations = mostRecentLocations;
		if (trackingFilters.onlyShowLatest) {
			const locationsByIdentityId = distinctByIdentity(mostRecentLocations);
			unsortedLocations = Object.values(locationsByIdentityId).mapNotNull((it) => it);
		}
		return unsortedLocations.sort((a, b) => sortByTimestamp(b.location, a.location));
	}
);

export const selectMostRecentLocationsForSidebar = createSelector(
	[selectMostRecentLocationsForWorkspaceFilter, selectWorkspaceFilters],
	(locations, filteredWorkspaces) => {
		if (locations === undefined || filteredWorkspaces === undefined) return undefined;
		if (locations === null) return null;

		const locationsFilteredByWorkspace = locations.filter((it) => {
			if (Object.keys(filteredWorkspaces).isEmpty()) return true; // there are no workspace filters

			const workspaceId = it.contact.workspaceId;

			if (WorkspaceUtil.isPersonal(workspaceId) && filteredWorkspaces["-1"] !== false)
				return true;
			if (workspaceId === undefined) return false;
			return filteredWorkspaces[workspaceId] !== false;
		});

		return [...locationsFilteredByWorkspace].sort((a, b) =>
			sortByTimestamp(b.location, a.location)
		);
	}
);

export const selectMostRecentLocationsForMap = createSelector(
	[selectMostRecentLocationsForSidebar, selectIsHiddenByUser],
	(mostRecentLocations, hiddenUserDict) => {
		if (mostRecentLocations === undefined) return undefined;
		if (mostRecentLocations === null) return null;
		return mostRecentLocations.filter((mostRecentLocation) => {
			return hiddenUserDict[mostRecentLocation.contact.id] !== true;
		});
	}
);

export const selectMostRecentLocationForUserIdForSidebar = createSelector(
	[selectMostRecentLocationsForSidebar, selectContactById],
	(locations, contact) => {
		if (locations === undefined || contact === undefined) return undefined;
		if (locations === null) return null;
		return locations.find((it) => it.contact.id === contact.id);
	}
);

export const selectMostRecentLocationsForActiveUser = createSelector(
	[selectMostRecentLocationsForMap, selectActiveTrackingUserId],
	(locations, userId) => {
		if (locations === undefined || userId === undefined) return undefined;
		if (locations === null) return null;
		return locations.find((it) => it.contact.id === userId);
	}
);

export const selectMostRecentLocationForActiveUserIfJump = createSelector(
	[selectActiveTrackingUser, selectMostRecentLocationsForActiveUser],
	(activeUser, location) => {
		if (!activeUser?.jump) return undefined;
		return location;
	}
);

/*
export const sortLocationsNotHiddenFiltered = createSelector(
	[sortLocationsNotHidden, selectUserIdsWithVisibleLocations],
	(locations, visibleUserIds) => {
		const filteredLocations: Dict<SortedLocations> = {};
		Object.keys(locations)
			.filter((userId) => visibleUserIds.includes(userId))
			.forEach((userId) => {
				filteredLocations[userId] = locations[userId];
			});

		return filteredLocations;
	}
);
*/

export const selectAssetCountsByWorkspaceId = createSelector(
	[selectUnarchivedContacts],
	(assets) => {
		const assetCounts: Dictionary<number> = {};
		assets.forEach((asset) => {
			const workspaceId = asset.workspaceId ?? PERSONAL_WORKSPACE_ID;
			if (assetCounts[workspaceId] === undefined) assetCounts[workspaceId] = 0;
			assetCounts[workspaceId] = assetCounts[workspaceId]! + 1;
		});
		return assetCounts;
	}
);

export const selectAssetWithLocationCountsByWorkspaceId = createSelector(
	[selectUnarchivedContacts, selectMostRecentLocationsForDateRangeByUserId],
	(assets, locationDict) => {
		if (locationDict === undefined) return undefined;
		if (locationDict === null) return null;
		const contactIdsWithLocations = Object.keys(locationDict);
		return getWorkspaceAssetCounts(assets, contactIdsWithLocations);
	}
);

export const selectAssetWithVisibleLocationCountsByWorkspaceId = createSelector(
	[selectUnarchivedContacts, selectMostRecentLocationsForWorkspaceFilter],
	(assets, locations) => {
		if (locations === undefined) return undefined;
		if (locations === null) return null;
		const contactIdsWithLocations = locations.map((location) => location.contact.id);
		return getWorkspaceAssetCounts(assets, contactIdsWithLocations);
	}
);

function getWorkspaceAssetCounts(assets: IContactForDisplay[], contactIdsWithLocations: string[]) {
	const assetCounts: Dictionary<number> = {};
	assets.forEach((asset) => {
		const workspaceId = asset.workspaceId ?? PERSONAL_WORKSPACE_ID;
		if (!contactIdsWithLocations.includes(asset.id)) return;
		if (assetCounts[workspaceId] === undefined) assetCounts[workspaceId] = 0;
		assetCounts[workspaceId] = assetCounts[workspaceId]! + 1;
	});
	return assetCounts;
}

export const selectDetailedWorkspaceEntitiesSorted = createSelector(
	[
		selectWorkspaceEntities,
		selectOrganizationEntities,
		selectAssetCountsByWorkspaceId,
		selectAssetWithVisibleLocationCountsByWorkspaceId,
	],
	(workspaces, organizations, assetCounts, visibleAssetCounts) => {
		if (assetCounts === undefined || visibleAssetCounts === undefined) return undefined;
		if (visibleAssetCounts === null) return null;
		return Object.values(workspaces)
			.mapNotNull<IWorkspaceEntity>((workspace) => {
				if (workspace === undefined) return undefined;
				if (!workspace.userIsMember) return undefined;
				const organization =
					workspace?.organizationId !== undefined
						? organizations[workspace.organizationId]
						: undefined;
				const assetCount = assetCounts[workspace.id];
				const visibleAssetCount = visibleAssetCounts[workspace.id];
				return {
					...workspace,
					organization: organization,
					assetCount: assetCount ?? 0,
					visibleAssetCount: visibleAssetCount ?? 0,
				};
			})
			.sort((a, b) => {
				if (WorkspaceUtil.isPersonal(a)) return 1;
				if (WorkspaceUtil.isPersonal(b)) return -1;
				if (a.visibleAssetCount! > 0 && b.visibleAssetCount === 0) return -1;
				if (b.visibleAssetCount! > 0 && a.visibleAssetCount === 0) return 1;
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				else return 0;
			});
	}
);

export const selectRouteIdsForMap = createSelector(
	[selectSortedLocationsForGlobalDateRangeByUserId],
	(locationDict) => {
		if (locationDict === undefined || locationDict === null) return [];
		const locations = Object.values(locationDict)
			.flatMap((locations) => locations)
			.mapNotNull((loc) => loc);
		const routeIds = _.uniq(locations.map((loc) => loc.routeId));
		return routeIds;
	}
);

export const selectFocusedTrackingLocation = createSelector(
	[selectFocusedTrackingUserId, selectMostRecentLocationsForDateRangeByUserId],
	(focusedUserId, locationDict) => {
		if (focusedUserId === undefined || locationDict === undefined) return undefined;
		if (locationDict === null) return null;
		return locationDict[focusedUserId];
	}
);

export const selectFocusedTrackingLocationId = createSelector(
	[selectFocusedTrackingLocation],
	(location) => location?.id
);

// LOCATION LINKED LIST & HELPER METHODS

// this type is used locally for breadcrumb clustering purposes, it should not leak into the greater codebase at this time
type LocationNode = ProcessedLocation & {
	next: LocationNode | undefined;
	prev?: LocationNode | undefined;
	collapsedLocations: LocationNode[];
	added?: boolean;
};

/**
 * Builds a linked list from tail to head for a given set of locations (assumes sorted by timestamp descending)
 * and returns all the nodes sorted from head to tail (where the head point is the most recent location).
 *
 * note: the ergonomics of this function would be cleaner if the linked list also went from head -> tail.
 */
function getNodeList(locations: LocationResponse.AsObject[]): LocationNode[] {
	let prevNode: LocationNode | undefined;
	return locations.map((location) => {
		const node = {
			...location,
			next: prevNode,
			collapsedLocations: [],
		} as LocationNode;

		prevNode = node;
		return node;
	});
}

/**
 * Used to find the center point of a given location node.
 * If the node isn't virtual, it acts as a simple transform from location -> point.
 * If the node is virtual, it finds the center point of the collapse locations.
 *
 * This is helpful when trying to measure the distance between a virtual node and a real node,
 * to determine whether the real node should be collapsed into the virtual node.
 *
 * We use the center point of a set of clustered breadcrumbs for distance measurements as it is a
 * more accurate representation of the asset's actual location over any single real node.
 */
function getBreadcrumbClusterCenterPoint(node: LocationNode): Point {
	if (!isVirtual(node)) {
		return {
			type: "Point",
			coordinates: [node.coordinate!.longitude, node.coordinate!.latitude],
		};
	}

	const pointFeatures = node.collapsedLocations
		.mapNotNull((location) => location.coordinate)
		.map((coord) => {
			return {
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [coord.longitude, coord.latitude],
				},
			} as Feature<Point>;
		});

	const centerFeature = turfCenter({
		type: "FeatureCollection",
		features: pointFeatures,
	});

	return centerFeature.geometry as Point;
}

/**
 * This function handles simplifying a track of locations (breadcrumb trail) into a smaller set of locations.
 * It does this by collapsing breadcrumbs that are close together into a single "virtual" node.
 *
 * If a virtual node is selected, it will be exploded into its constituent nodes.
 *
 * note: the ergonomics of this function would be better if the root of the linked list was the head rather than the tail
 */
function collapseNodes(
	head: LocationNode,
	tail: LocationNode,
	selectedLocationId: string | undefined
): ProcessedLocation[] {
	if (head.id === tail.id || head.next === undefined) return [head];
	if (head.next.id === tail.id) return [head, tail];

	// start at the second node, the first is not collapsible
	let anchorNode = head.next!;
	let node = anchorNode.next!;
	const cleanedNodeList: LocationNode[] = [];
	let hasExplodedLocation = false;
	do {
		const centerCoord = getBreadcrumbClusterCenterPoint(anchorNode).coordinates;
		const currentCoord = node.coordinate!;
		const distance = turfDistance(
			[centerCoord[0], centerCoord[1]],
			[currentCoord.longitude, currentCoord.latitude],
			{
				units: "meters",
			}
		);

		if (distance < CLEAN_MIN_DISTANCE_IN_METERS) {
			const previousCollapsedLocations = anchorNode.virtual
				? anchorNode.collapsedLocations
				: [anchorNode];

			const collapsedLocations = [...previousCollapsedLocations, node];

			const isExploded =
				selectedLocationId !== undefined &&
				collapsedLocations.find((it) => it.id === selectedLocationId) !== undefined;

			if (isExploded) hasExplodedLocation = true;

			const virtualNode = {
				...anchorNode,
				coordinate: {
					longitude: centerCoord[0],
					latitude: centerCoord[1],
					longitudeDeprecated: 0,
					latitudeDeprecated: 0,
				},
				next: node.next,
				collapsedLocations: collapsedLocations,
				prev: anchorNode.prev,
				exploded: isExploded,
				virtual: true,
			} as LocationNode;

			// we have to update its parent's pointer to the virtual node
			if (anchorNode.prev !== undefined) anchorNode.prev.next = virtualNode;
			anchorNode = virtualNode;
		} else {
			anchorNode.added = true;
			cleanedNodeList.push(anchorNode);
			node.prev = anchorNode;
			anchorNode = node;
		}

		// skip the head node, as it's not collapsible
		if (node.next?.next === undefined) break;
		node = node.next;
	} while (true);

	// make sure the last node gets added
	if (!anchorNode.added) cleanedNodeList.push(anchorNode);

	// explodes the locations for the selectedNode
	if (hasExplodedLocation) {
		const explodedLocation = cleanedNodeList.find((it) => isVirtual(it) && it.exploded)!;
		const explodedLocationIndex = cleanedNodeList.indexOf(explodedLocation);
		cleanedNodeList.splice(
			explodedLocationIndex,
			1, // todo: this deletes the virtual location (we might want this later)
			...explodedLocation.collapsedLocations
		);
	}

	return [head, ...cleanedNodeList, tail] as ProcessedLocation[];
}

// exporting for unit testing purposes
export namespace LocationSelectors {
	export const _getNodeList = getNodeList;
	export const _collapseNodes = collapseNodes;
}
