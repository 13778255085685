import type { CreateHealthActivityResponse } from "@somewear/api";
import { selectActiveUserAccountId } from "@somewear/auth";
import type { IBiometrics } from "@somewear/biometric";
import {
	apiHealthActivityError,
	apiHealthActivityRequest,
	apiHealthActivitySuccess,
} from "@somewear/biometric";
import { grpc, someGrpc } from "@somewear/grpc";
import type { ActionSetEpic, CreateHealthActivityForm } from "@somewear/model";
import { createActionSetEpicHandler, haltAll } from "@somewear/model";
import type { AnyAction } from "redux";
import type { Epic } from "redux-observable";
import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import { biometricActions } from "./biometric.actions";
import type { BiometricState } from "./biometric.state";

const apiHealthActivityEpic: Epic<AnyAction, AnyAction> = (action$, state$) =>
	action$.pipe(
		ofType(apiHealthActivityRequest.type),
		switchMap(() => {
			return grpc.prepareRequest(someGrpc.getHealthActivity).pipe(
				map((activities) => {
					const activeUserAccountId = selectActiveUserAccountId(state$.value)!;
					const biometrics: IBiometrics[] = activities
						.toObject()
						.healthactivityresponseList.map((activity) => {
							if (activity.owner?.id !== undefined) {
								return {
									...activity,
									ownerId: activity.owner.id!,
								};
							} else {
								return {
									...activity,
									ownerId: activeUserAccountId,
								};
							}
						});
					return apiHealthActivitySuccess(biometrics);
				}),
				takeUntil(action$.pipe(ofType(haltAll.type))),
				catchError((error) => of(apiHealthActivityError(error)))
			);
		})
	);

const createHealthActivityEpic: ActionSetEpic<
	CreateHealthActivityForm,
	CreateHealthActivityResponse
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		biometricActions.createHealthActivity,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.createHealthActivity, payload.data)
	);
};

export const biometricEpics = combineEpics<any, any, BiometricState>(
	apiHealthActivityEpic,
	createHealthActivityEpic
);
