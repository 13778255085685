import { combineReducers } from "redux";

import { workspaceKeysSlice } from "./workspaceKeysSlice";
import { workspacesSlice } from "./workspaces.slice";

export const workspaceReducerMap = {
	workspaces: workspacesSlice.reducer,
	workspaceKeys: workspaceKeysSlice.reducer,
};

const reducers = combineReducers(workspaceReducerMap);

export type WorkspaceState = ReturnType<typeof reducers>;
