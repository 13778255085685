import { IdentityType } from "@somewear/api";
import { selectActiveIdentityId, selectActiveWorkspaceId } from "@somewear/auth";
import type { IWorkspaceAsset, WorkspaceRoleType } from "@somewear/model";
import { selectEcho } from "@somewear/model";
import { createSelector } from "reselect";

import {
	selectAllWorkspaceAssetsWithTypeAndExternalId,
	selectWorkspaceAssetsWithTypeForActiveWorkspace,
} from "./asset.selectors";
import type { AssetsState } from "./assetsState";

export const selectAllWorkspaceMembers = createSelector(
	[selectWorkspaceAssetsWithTypeForActiveWorkspace],
	(members) => {
		return members.filter((user) => user.type === IdentityType.USER && !user.isArchived);
	}
);

export const selectAllArchivedWorkspaceMembers = createSelector(
	[selectWorkspaceAssetsWithTypeForActiveWorkspace],
	(members) => {
		return members.filter((user) => user.type === IdentityType.USER);
	}
);

const assetSorter = (assets: IWorkspaceAsset[]) => {
	return assets.sort((a, b) => {
		const aName = a.fullname.toLowerCase();
		const bName = b.fullname.toLowerCase();
		if (aName === undefined) return 1;
		if (bName === undefined) return -1;
		if (aName === bName) return 0;
		return aName < bName ? -1 : 1;
	});
};

const selectAllWorkspaceMembersSorted = createSelector([selectAllWorkspaceMembers], assetSorter);

export const selectMembersSortedForWorkspace = createSelector(
	[
		selectAllWorkspaceMembersSorted,
		selectActiveWorkspaceId,
		selectEcho<string | undefined, AssetsState>,
	],
	(members, activeWorkspaceId, specifiedWorkspaceId) => {
		const workspaceId = specifiedWorkspaceId ?? activeWorkspaceId;
		if (workspaceId === undefined) return [];
		return members.filter((it) => it.workspaceId === workspaceId);
	}
);

interface IWorkspaceMembership {
	identityId: string;
	workspaceId: string;
	workspaceRole: WorkspaceRoleType;
}
export const selectSelfWorkspaceRoles = createSelector(
	[selectAllWorkspaceAssetsWithTypeAndExternalId, selectActiveIdentityId],
	(members, identityId) => {
		return members
			.filter((member) => member.identityId === identityId)
			.map((member) => {
				return {
					identityId: member.identityId,
					workspaceId: member.workspaceId,
					workspaceRole: member.workspaceRole,
				} as IWorkspaceMembership;
			});
	}
);
