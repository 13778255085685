import type {
	ArchiveMembersResponse,
	CreateResourceAccountResponse,
	DeviceList,
	ToggleWorkspaceIntegrationRequest,
	UserResponse,
	WorkspaceResponseList,
} from "@somewear/api";
import { WorkspaceAccess } from "@somewear/api";
import { selectActiveWorkspaceId, setActiveWorkspaceId } from "@somewear/auth";
import { grpc, someGrpc } from "@somewear/grpc";
import type { ActionSetEpic } from "@somewear/model";
import {
	actionSetEpicHandlerBuilder,
	createActionSetEpicHandler,
	transformerEpicBuilder,
} from "@somewear/model";
import { combineEpics } from "redux-observable";

import { workspaceActions } from "./workspace.actions";
import { PERSONAL_WORKSPACE_ID } from "./workspace.model";
import { clearActiveWorkspace, clearWorkspace, updateWorkspaceAccess } from "./workspaces.slice";

/*const fetchWorkspaceAccountsEpic: ActionSetEpic<void, UserResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, workspaceActions.fetchAccounts, () =>
		grpc
			.prepareRequest(someGrpc.getWorkspaceAccounts)
			.pipe(map((accounts) => accounts.toObject().responsesList))
	);
};*/

const fetchWorkspaceDevicesEpic: ActionSetEpic<void, DeviceList.AsObject> = (action$, state$) => {
	return createActionSetEpicHandler(action$, state$, workspaceActions.fetchDevices, () =>
		grpc.prepareRequest(someGrpc.getWorkspaceDevices)
	);
};

const fetchAllWorkspacesEpic: ActionSetEpic<void, WorkspaceResponseList.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, workspaceActions.fetchWorkspaces, () =>
		grpc.prepareRequest(someGrpc.fetchAllWorkspaces)
	);
};

const leaveWorkspaceEpic: ActionSetEpic<string, ArchiveMembersResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, workspaceActions.leaveWorkspace, (payload) =>
		grpc.prepareRequestWithPayload(someGrpc.leaveWorkspace, payload.data)
	);
};

const bulkToggleIntegrationsEpic = actionSetEpicHandlerBuilder(
	workspaceActions.bulkToggleIntegrations,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.bulkToggleIntegrations, payload.data)
);

const toggleIntegrationEpic: ActionSetEpic<
	ToggleWorkspaceIntegrationRequest.AsObject,
	UserResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		workspaceActions.toggleIntegration,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.toggleIntegration, payload.data)
	);
};

/*const fetchWorkspacesEpic: ActionSetEpic<void, WorkspaceResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, workspaceActions.fetchWorkspaces, () =>
		grpc
			.prepareRequest(someGrpc.getWorkspaces)
			.pipe(map((workspaces) => workspaces.toObject().responsesList))
	);
};*/

/*
Get all workspaces and dispatch the action to set it on the store
 */
/*const apiWorkspacesEpic: Epic<Action> = (action$, state$: StateObservable<RootState>) =>
	action$.pipe(
		filter(workspaceActions.fetchWorkspaces.fulfilled.match),
		mergeMap((action) => {
			// const workspaces = action.payload.data;
			let actions: Action[] = [];
			const activeWorkspace = selectActiveWorkspace(state$.value);
			if (activeWorkspace !== undefined) {
				if (WorkspaceUtil.isTeam(activeWorkspace)) {
					// actions.push(createUserStates());
					/!*const teamWorkspaces = workspaces.filter((workspace) =>
						WorkspaceUtil.isTeam(workspace)
					);
					actions.push(createWorkspaceConversations(teamWorkspaces));*!/
				}
			}
			return actions;
		}),
		takeUntil(action$.pipe(ofType(signedOut.type)))
	);*/

const createWorkspaceResourceEpic: ActionSetEpic<string, CreateResourceAccountResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		workspaceActions.createResource,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.createResourceAccount, payload.data),
		() => {
			return {
				onPending: `Creating resource...`,
				onRejected: `Error creating resource.`,
				onFulfilled: `Successfully created resource.`,
			};
		}
	);
};

const clearWorkspaceEpic = transformerEpicBuilder(updateWorkspaceAccess, (action, state) => {
	const workspaceId = action.payload.id;
	const access = action.payload.access;
	if (
		access === WorkspaceAccess.WORKSPACEACCESSCLOSED ||
		access == WorkspaceAccess.WORKSPACEACCESSDELETED
	) {
		return [clearWorkspace(workspaceId)];
	}
	return [];
});

const clearActiveWorkspaceEpic = transformerEpicBuilder(clearWorkspace, (action, state) => {
	const activeWorkspaceId = selectActiveWorkspaceId(state);
	if (activeWorkspaceId === action.payload) {
		return [clearActiveWorkspace()];
	}
	return [];
});

const resetActiveWorkspaceIdEpic = transformerEpicBuilder(clearActiveWorkspace, () => {
	return [setActiveWorkspaceId(PERSONAL_WORKSPACE_ID)];
});

export const workspaceEpics = combineEpics<any, any, any>(
	fetchWorkspaceDevicesEpic,
	// fetchWorkspacesEpic,
	// fetchWorkspaceAccountsEpic,
	// apiWorkspacesEpic
	leaveWorkspaceEpic,
	toggleIntegrationEpic,
	bulkToggleIntegrationsEpic,
	fetchAllWorkspacesEpic,
	createWorkspaceResourceEpic,
	clearWorkspaceEpic,
	clearActiveWorkspaceEpic,
	resetActiveWorkspaceIdEpic
);
