import type { IntegrationResponse } from "@somewear/api";
import { GetOrganizationIntegrationAccountsResponse } from "@somewear/api";
import type { IIntegrationIdentity } from "@somewear/model";

export function getIntegrationType(integration: IntegrationResponse.AsObject) {
	if (Boolean(integration.takServer)) {
		return GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationType.TAK;
	}

	if (Boolean(integration.webhook)) {
		return GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationType
			.WEBHOOK;
	}

	return GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationType.NONE;
}

export function getIntegrationDisplayType(integration: IIntegrationIdentity) {
	if (
		integration.integrationType ===
		GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationType.TAK
	) {
		return "TAK Server";
	}

	if (
		integration.integrationType ===
		GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationType.WEBHOOK
	) {
		return "Webhook";
	}

	return null;
}

export function formatIntegration(
	integration: GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.AsObject
): IIntegrationIdentity | undefined {
	if (integration.identity === undefined) return undefined;

	return {
		...integration.identity,
		beamEnabled: integration.beamEnabled,
		inboundEnabled: integration.inboundEnabled,
		outboundEnabled: integration.outboundEnabled,
		inboundLastUpdated: integration.inboundLastUpdated,
		outboundLastUpdated: integration.outboundLastUpdated,
		defaultWorkspaceId: integration.defaultWorkspaceId,
		integrationType: integration.integrationType,
	};
}
