import { clear, createInstance } from "localforage";
import { from, Observable, of } from "rxjs";
import { concatAll, mergeMap, toArray } from "rxjs/operators";

export namespace StoreController {
	export const STORE_MAP_LAYERS = "mapLayers";
	export const STORE_MAP_VIEW = "mapView";
	export const STORE_AUTH_DATA = "authData";
	export const USER_DATA_SYNC = "userDataSync";

	export type STORE_NAME = "mapLayers" | "mapData" | "authData";

	export const VERSIONS = {
		mapLayers: 2,
		mapData: 1,
		authData: 1,
	};

	export function getStore(store: STORE_NAME, version?: number) {
		return createInstance({ storeName: `${store}-${version ?? VERSIONS[store]}` });
	}

	export async function clearAllStores(): Promise<void> {
		localStorage.clear();
		return clear();
	}

	export function clearStore(storeName: STORE_NAME): Observable<void[]> {
		const store = getStore(storeName);
		return from(store.keys()).pipe(
			concatAll(),
			mergeMap((key) => {
				return removeItem(storeName, key);
			}),
			toArray()
		);
	}

	export function getAllItems<T>(
		storeName: STORE_NAME,
		version?: number
	): Observable<(T | undefined)[]> {
		const store = getStore(storeName);
		return from(store.keys()).pipe(
			concatAll(),
			mergeMap((key) => {
				return getItem<T | undefined>(storeName, key, version);
			}),
			toArray()
		);
	}

	export function setItem<T>(
		storeName: STORE_NAME,
		key: string,
		item: T,
		version?: number
	): Observable<T> {
		const store = getStore(storeName, version);
		return new Observable<T>((subscriber) => {
			store
				.setItem<T>(key, item)
				.then((it) => {
					if (it) subscriber.next(it);
					subscriber.complete();
				})
				.catch((e) => {
					subscriber.error("Unable to find item");
				});
		});
	}

	export function getItem<T>(
		storeName: STORE_NAME,
		key: string,
		version?: number
	): Observable<T | undefined> {
		const store = getStore(storeName, version);
		return new Observable<T>((subscriber) => {
			store
				.getItem<T>(key)
				.then((it) => {
					if (it) subscriber.next(it);
					else subscriber.next(undefined);
					subscriber.complete();
				})
				.catch((e) => {
					subscriber.error("Unable to retrieve item");
				});
		});
	}

	export function removeItem(
		storeName: STORE_NAME,
		key: string,
		version?: number
	): Observable<void> {
		const store = getStore(storeName, version);
		return new Observable<void>((subscriber) => {
			store
				.removeItem(key)
				.then((it) => {
					subscriber.complete();
				})
				.catch((e) => {
					subscriber.error("Unable to find item");
				});
		});
	}

	export function massUpdate<T>(
		storeName: STORE_NAME,
		entities: T[],
		keyFactory: (entity: T) => string
	): Observable<T[]> {
		return of(entities).pipe(
			concatAll(),
			mergeMap((entity) => {
				return setItem<T>(storeName, keyFactory(entity), entity);
			}),
			toArray()
		);
	}

	export function massUpdateWithKeys<T>(
		storeName: STORE_NAME,
		entities: T[],
		keys: string[]
	): Observable<T[]> {
		return of(entities).pipe(
			concatAll(),
			mergeMap((entity, index) => {
				return setItem<T>(storeName, keys[index], entity);
			}),
			toArray()
		);
	}
}
