import { grpc, someGrpc } from "@somewear/grpc";
import { haltAll } from "@somewear/model";
import type { Action } from "redux";
import type { Epic } from "redux-observable";
import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import { apiSosEventsError, apiSosEventsRequest, apiSosEventsSuccess } from "./index";

/*
Get active sos events
 */
const apiSosEventsEpic: Epic<Action<string>> = (action$) =>
	action$.pipe(
		ofType(apiSosEventsRequest.type),
		switchMap(() =>
			grpc.prepareRequest(someGrpc.getActiveSosEvents).pipe(
				map((events) => apiSosEventsSuccess(events.toObject().eventsList)),
				takeUntil(action$.pipe(ofType(haltAll.type))),
				catchError((error) => of(apiSosEventsError(error)))
			)
		)
	);

export const sosEpics = combineEpics(apiSosEventsEpic);
