import type { Dictionary, EntityState } from "@reduxjs/toolkit";
import { IdentityType } from "@somewear/api";
import {
	selectAllContacts,
	selectIdentityEntities,
	selectUnarchivedContacts,
} from "@somewear/asset";
import type { IContactForDisplay, IContactForDisplayWithWorkspace } from "@somewear/model";
import { getEntityColor, SWL_COLOR_BLUE } from "@somewear/model";
import {
	getColorForWorkspace,
	PERSONAL_WORKSPACE_NAME,
	selectWorkspaceEntities,
} from "@somewear/workspace";
import { createSelector } from "reselect";

export const selectContactWorkspaceColor = createSelector(
	[selectAllContacts, selectIdentityEntities, selectWorkspaceEntities],
	(contacts, identityDict, workspaceDict) => {
		const results: Dictionary<string> = {};
		if (Object.keys(workspaceDict).length > 1) {
			contacts.forEach((contact) => {
				const identity =
					contact.identityId !== undefined ? identityDict[contact.identityId] : undefined;
				const standardIdentityColor = identity?.styleSettings?.standardColor;

				const identityColor = getEntityColor(contact.name, standardIdentityColor);

				const workspace =
					contact.workspaceId !== undefined
						? workspaceDict[contact.workspaceId]
						: undefined;

				const standardWorkspaceColor = workspace?.styleSettings?.standardColor;

				const workspaceColor = getEntityColor(workspace?.name, standardWorkspaceColor);

				if (workspace !== undefined) {
					return workspaceColor;
				} else {
					return identityColor;
				}
			});
		} else {
			contacts.forEach((contact) => {
				results[contact.id] = SWL_COLOR_BLUE;
			});
		}
		return results;
	}
);

export const selectAllContactsWithWorkspace = createSelector(
	[selectUnarchivedContacts, selectWorkspaceEntities],
	(contacts, workspaces) => {
		return contacts.mapNotNull((contact) => {
			if (contact.isArchived) return undefined;
			const workspace =
				contact.workspaceId !== undefined ? workspaces[contact.workspaceId] : undefined;
			const workspaceName =
				workspace !== undefined ? workspace.name : PERSONAL_WORKSPACE_NAME;

			if (workspace === undefined && contact.identityId === undefined) return undefined; // the user likely doesn't have access to this workspace anymore, this also occurs when the contact is public

			const workspaceColor =
				workspace !== undefined ? getColorForWorkspace(workspace) : SWL_COLOR_BLUE;

			return {
				...contact,
				sharesWorkspace: Boolean(workspace),
				workspaceColor: workspaceColor,
				workspaceName: workspaceName,
			} as IContactForDisplayWithWorkspace;
		});
	}
);

export const selectAllUsersWithWorkspace = createSelector(
	[selectAllContactsWithWorkspace],
	(contacts) => {
		return contacts.filter((asset) => asset.type === IdentityType.USER);
	}
);

export const selectAllWorkspaceUsers = createSelector([selectAllUsersWithWorkspace], (users) =>
	users.filter((user) => user.sharesWorkspace && user.workspace)
);

export const selectAllContactsWithWorkspaceAsEntityState = createSelector(
	[selectAllContactsWithWorkspace],
	(contacts) => {
		const entities: Dictionary<IContactForDisplay> = {};
		contacts.forEach((contact) => {
			entities[contact.id] = contact;
		});
		return {
			ids: Object.keys(entities),
			entities: entities,
		} as EntityState<IContactForDisplayWithWorkspace>;
	}
);
