import { IdentityRecord, OrganizationRole } from "@somewear/api";
import { selectActiveIdentityId } from "@somewear/auth";
import type { IIntegrationIdentity } from "@somewear/model";
import { getDictionaryValue } from "@somewear/model";
import { sortBy } from "lodash";
import { createSelector } from "reselect";

import { selectAllIdentities, selectIdentityById, selectIdentityEntities } from "./identitiesSlice";

export const selectActiveIdentity = createSelector(
	[selectIdentityEntities, selectActiveIdentityId],
	(identityDict, id) => getDictionaryValue(identityDict, id)
);

export const selectOrganizationRole = createSelector([selectActiveIdentity], (activeIdentity) => {
	return activeIdentity?.organizationRole ?? OrganizationRole.ORGANIZATIONROLENONE;
});

export const selectIsOrganizationAdmin = createSelector(
	[selectActiveIdentity],
	(activeIdentity) => {
		return activeIdentity?.organizationRole === OrganizationRole.ORGANIZATIONROLEADMIN;
	}
);

export const selectAllUserIdentitiesWithoutOrganizationSorted = createSelector(
	[selectAllIdentities],
	(contactIdentities) => {
		const filteredIdentities = contactIdentities.filter(
			(c) => !c.organizationId && c.type === IdentityRecord.Type.USER
		);

		return sortBy(filteredIdentities, "fullName");
	}
);

export const selectIntegrationIdentityById = createSelector([selectIdentityById], (identity) => {
	return identity as IIntegrationIdentity;
});
