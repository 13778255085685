import type { Dictionary } from "@reduxjs/toolkit";
import { selectAllContacts, selectContactById } from "@somewear/asset";
import { selectAllDevices } from "@somewear/device";
import type { IDevice } from "@somewear/model";
import { createSelector } from "reselect";

export const selectContactDeviceMap = createSelector(
	[selectAllContacts, selectAllDevices],
	(assets, devices) => {
		const assetDeviceMap: Dictionary<IDevice> = {};
		assets.forEach((asset) => {
			const device = devices.find((device) => device.userAccountId === asset.id);
			assetDeviceMap[asset.id] = device;
		});
		return assetDeviceMap;
	}
);

export const selectContactHasDeviceMap = createSelector(
	[selectAllContacts, selectAllDevices],
	(assets, devices) => {
		const assetHasDeviceMap: Dictionary<boolean> = {};
		assets.forEach((asset) => {
			const device = devices.find((device) => device.userAccountId === asset.id);
			assetHasDeviceMap[asset.id] = device !== undefined;
		});
		return assetHasDeviceMap;
	}
);

export const selectContactDevice = createSelector(
	[selectContactDeviceMap, selectContactById],
	(deviceDict, contact) => {
		if (contact?.id === undefined) return undefined;
		return deviceDict[contact.id];
	}
);
