import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { DeleteRouteRequest, RouteResponse } from "@somewear/api";
import { StyleSettings } from "@somewear/api";
import { emitAssetAccountsDeleted } from "@somewear/asset";
import { clearWorkspace } from "@somewear/workspace";

import { waypointActions } from "./waypoint.actions";
import type { WaypointState } from "./waypoint.state";

export interface IWaypoint extends RouteResponse.AsObject {}

export const isTakWaypoint = (metadata?: StyleSettings.AsObject): boolean => {
	return (
		metadata !== undefined &&
		metadata.affiliation !== StyleSettings.Affiliation.AFFILIATIONNONE &&
		metadata.dimension !== StyleSettings.Dimension.DIMENSIONNONE
	);
};

const adapter = createEntityAdapter<IWaypoint>({
	selectId: (entity) => entity.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllWaypoints,
	selectById: selectWaypointById,
	selectEntities: selectWaypointEntities,
} = adapter.getSelectors((state: WaypointState) => state.waypoints);

export const waypointsSlice = createSlice({
	name: "waypoints",
	initialState: adapter.getInitialState(),
	reducers: {
		// _: (state) => {},
		addWaypoint: (state, action: PayloadAction<RouteResponse.AsObject>) => {
			adapter.addOne(state, action.payload);
		},
		updateWaypoint: (state, action: PayloadAction<RouteResponse.AsObject>) => {
			adapter.addOne(state, action.payload);
		},
		removeWaypoint: (state, action: PayloadAction<DeleteRouteRequest.AsObject>) => {
			adapter.removeOne(state, action.payload.routeId);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(waypointActions.fetch.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.routesList);
		});
		builder.addCase(waypointActions.saveWaypoint.fulfilled, (state, action) => {
			adapter.upsertOne(state, action.payload.data);
		});
		builder.addCase(waypointActions.deleteWaypoint.fulfilled, (state, action) => {
			adapter.removeOne(state, action.payload.data.routeId);
		});
		builder.addCase(clearWorkspace, (state, action) => {
			const waypoints = adapter.getSelectors().selectAll(state);
			// note: we don't support sharing with multiple workspaces right now, this might be over aggressively removing waypoints
			const workspaceWaypoints = waypoints.filter((it) =>
				it.workspaceIdsList.includes(action.payload)
			);
			adapter.removeMany(
				state,
				workspaceWaypoints.map((it) => it.id)
			);
		});
		builder.addCase(emitAssetAccountsDeleted, (state, action) => {
			// when an account is deleted, delete the related data
			const deletedAccountIds = action.payload.map((it) => it.id);
			const items = adapter.getSelectors().selectAll(state);
			const deletedItems = items.filter(
				(it) => it.ownerId !== undefined && deletedAccountIds.includes(it.ownerId)
			);
			adapter.removeMany(
				state,
				deletedItems.map((it) => it.id)
			);
		});
	},
});
