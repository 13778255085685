import classNames from "classnames";
import * as React from "react";

interface IProps {
	text?: string;
	fullScreen?: boolean;
}

const Loader: React.FunctionComponent<IProps> = (props: IProps) => (
	<div
		className={classNames({
			"w-full h-full fixed left-0 top-0 bg-white/50 flex z-[9998]": props.fullScreen,
		})}
	>
		<div className="flex flex-col justify-center m-auto">
			{props.text ? <div className="loader-text">{props.text}</div> : <div />}
			<div className="loader-container">
				<div className="loader" />
			</div>
		</div>
	</div>
);

export default Loader;
