import type { Observable } from "rxjs";
import { of, retry, timer } from "rxjs";

export interface IResettableRetry {
	errorCount: number;
}

const ResetMessages = [
	"stream timeout",
	"upstream request timeout",
	"upstream connect error or disconnect/reset before headers. reset reason: remote reset",
];

// https://dev.to/this-is-learning/rxjs-retry-with-exponential-backoff-dpe
export function backoffRetry<T>() {
	return (obs$: Observable<T>) =>
		obs$.pipe(
			retry({
				delay: (error: Error | undefined, retryIndex) => {
					// if stream timeout retry immediately, else increase timeout with every error
					if (ResetMessages.includes(error?.message ?? "")) {
						return of(1);
					}

					const d = Math.pow(2, retryIndex - 1) * 1000;
					return timer(d);
				},
			})
		);
}
