import { createAction } from "@reduxjs/toolkit";
import type {
	ArchiveMembersResponse,
	CreateResourceAccountResponse,
	DeviceList,
	ToggleWorkspaceIntegrationRequest,
	UserResponse,
	WorkspaceResponseList,
} from "@somewear/api";
import { createActionSet } from "@somewear/model";

import type { IWorkspace } from "./workspaces.slice";

export const emitWorkspacesReceived = createAction<IWorkspace[]>("emitWorkspaceReceived");
// todo: `emitOrganizationWorkspacesReceived` should be deprecated and removed. keeping for now to avoid breaking changes by modifying `emitWorkspacesReceived`
export const emitOrganizationWorkspacesReceived = createAction<IWorkspace[]>(
	"emitOrganizationWorkspacesReceived"
);

export const emitWorkspaceChangeFromServer = createAction<IWorkspace>(
	"emitWorkspaceChangeFromServer"
);

export const workspaceActions = {
	// fetchWorkspaces: createActionSet("workspaces/fetch"),
	// fetchAccounts: createActionSet("workspace/accounts/fetch"),
	fetchDevices: createActionSet<void, DeviceList.AsObject>("workspace/devices/fetch"),
	leaveWorkspace: createActionSet<string, ArchiveMembersResponse.AsObject>("workspace/leave"),
	toggleIntegration: createActionSet<
		ToggleWorkspaceIntegrationRequest.AsObject,
		UserResponse.AsObject
	>("workspace/integration/toggle"),
	bulkToggleIntegrations: createActionSet<
		ToggleWorkspaceIntegrationRequest.AsObject[],
		UserResponse.AsObject[]
	>("workspace/integration/toggle/bulk"),
	fetchWorkspaces: createActionSet<void, WorkspaceResponseList.AsObject>("workspaces/fetch"),
	createResource: createActionSet<string, CreateResourceAccountResponse.AsObject>(
		"workspace/resources/create"
	),
};

// clear the state and set the active workspace
export const updateActiveWorkspaceId = createAction<string>("updateActiveWorkspaceId");
