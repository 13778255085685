import type { FirebaseApp } from "firebase/app";
import { initializeApp } from "firebase/app";
import type { Database } from "firebase/database";
import { get, getDatabase, ref, set } from "firebase/database";
import { cloneDeep } from "lodash";
import { firstValueFrom } from "rxjs";

import type { AbstractStore } from "./abstractStore";

export class SyncController<T extends Record<string, any>> {
	private firebaseApp: FirebaseApp | null = null;
	protected database: Database | null = null;

	constructor(
		private localStore: AbstractStore<T>,
		private useFirebase: boolean,
		private userId: string,
		firebaseConfig?: any
	) {
		if (this.useFirebase && firebaseConfig) {
			this.firebaseApp = initializeApp(firebaseConfig);
			this.database = getDatabase(this.firebaseApp);
		}
	}

	private getFullPath(type: string, path: string = ""): string {
		return `${type}/${this.userId}/${path}`.replace(/\/$/, "");
	}

	private firebaseEnabled(): this is { database: Database } {
		return Boolean(this.useFirebase && this.database);
	}

	private async getLocalData(): Promise<T> {
		const currentData = (await this.localStore.read()) ?? ({} as T);

		return currentData;
	}

	async read<K extends keyof T>(type: K, path: string = ""): Promise<T[K] | undefined> {
		const fullPath = this.getFullPath(type as string, path);

		if (this.firebaseEnabled()) {
			try {
				const snapshot = await get(ref(this.database, fullPath));
				const firebaseData = snapshot.val();

				if (firebaseData !== null) {
					// If data exists in Firebase, update local store and return Firebase data
					await firstValueFrom(
						this.localStore.write$({ [type]: { [this.userId]: firebaseData } } as T)
					);

					return firebaseData;
				}
			} catch (error) {
				console.error("Error reading from Firebase:", error);
			}
		} else {
			// If not using Firebase or if Firebase read fails, fall back to local store
			const localData = await this.getLocalData();

			return localData[type]?.[this.userId];
		}
	}

	async write<K extends keyof T>(type: K, path: string = "", rawData: any): Promise<void> {
		const data = cloneDeep(rawData);
		const fullPath = this.getFullPath(type as string, path);

		if (this.firebaseEnabled()) {
			try {
				await set(ref(this.database, fullPath), data);
			} catch (error) {
				console.error("Error writing to Firebase:", error);
			}
		} else {
			const currentData = await this.getLocalData();
			const pathParts = path.split("/").filter(Boolean);
			const nestedData = pathParts.reduceRight((acc, part) => ({ [part]: acc }), data);

			const updatedData = {
				...currentData,
				[type]: {
					...currentData[type],
					[this.userId]: {
						...currentData[type]?.[this.userId],
						...nestedData,
					},
				},
			};

			await firstValueFrom(this.localStore.write$(updatedData));
		}
	}

	async remove<K extends keyof T>(type: K, path: string): Promise<void> {
		const fullPath = this.getFullPath(type as string, path);

		if (this.firebaseEnabled()) {
			try {
				await set(ref(this.database, fullPath), null);
			} catch (error) {
				console.error("Error removing from Firebase:", error);
			}
		} else {
			// Remove from local store
			const currentData = await this.getLocalData();

			if (path) {
				delete currentData[type]?.[this.userId]?.[path];
			} else {
				delete currentData[type]?.[this.userId];
			}

			await firstValueFrom(this.localStore.write$(currentData));
		}
	}
}
