import type { IfVoid } from "@reduxjs/toolkit/dist/tsHelpers";
import {
	Api,
	AuthController,
	selectActiveOrganizationId,
	selectActiveUserAccountId,
} from "@somewear/auth";
import { Configuration, TraceIdGenerator } from "@somewear/model";
import type { ClientReadableStream, Metadata } from "grpc-web";
import type { MonoTypeOperatorFunction } from "rxjs";
import { Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

const metadata$ = (skipUserCreate = false, omitAuth = false): Observable<Metadata> => {
	/*const user = firebaseCurrentAuthUser();
	if (!user) {
		throw Error("There is no authenticated user");
	}*/

	// if we're omitting auth, just pass along an undefined token, otherwise fetch the auth token

	return AuthController.tokenString$(omitAuth).pipe(
		map((tokenString) => {
			if (tokenString === undefined && !omitAuth) {
				console.error("An auth token wasn't provided");
				throw new Error("An auth token is required.");
			}
			const metadata: Metadata = {
				"x-api-key": Configuration.config.somewear.apiKey,
				"x-platform": "Web",
				"x-skip-user-create": skipUserCreate ? "true" : "false",
				"x-b3-traceid": TraceIdGenerator.instance.generateTraceId(),
				"x-b3-spanid": TraceIdGenerator.instance.generateSpanId(),
			};

			// were not omitting auth, so include the appropriate token headers
			if (!omitAuth && tokenString !== undefined) {
				metadata["Authorization"] = tokenString;
			}

			if (!skipUserCreate) {
				const state = Api.store.getState();
				const userAccountId = selectActiveUserAccountId(state);
				const organizationId = selectActiveOrganizationId(state);
				if (userAccountId) {
					metadata["x-user-account-id"] = userAccountId;
				} else {
					// console.error("there is no user account");
				}

				if (organizationId !== undefined) {
					metadata["x-organization-id"] = organizationId;
				}
			}

			return metadata;
		})
	);
};

function makeRequest<T>(
	request: (metadata: Metadata) => Promise<T> | Observable<T>,
	skipUserCreate?: boolean,
	omitAuth?: boolean
): Observable<T> {
	return metadata$(skipUserCreate, omitAuth).pipe(
		switchMap((metadata) => {
			return request(metadata);
		})
	);
}

export interface IGrpcRequestHandler<R> {
	(metadata: Metadata): Promise<R>;
}

export interface IGrpcRequestHandlerWithPayload<T, R> {
	(metadata: Metadata, payload: T): Promise<R>;
}

export class StreamData<T> {
	constructor(readonly stream: ClientReadableStream<T>, public restartStream: boolean = true) {}
}

type StreamLifecycleEvent = {
	_type: "StreamLifecycleEvent";
	event: "connect";
};

export function isStreamLifecycleEvent(obj: any): obj is StreamLifecycleEvent {
	return obj._type === "StreamLifecycleEvent";
}

// TODO: get [GrpcClientExtension] working
function streamToObservable<T>(stream: ClientReadableStream<T>, restart?: boolean) {
	return new Observable<T | StreamLifecycleEvent>((subscriber) => {
		stream.on("data", (response) => {
			subscriber.next(response);
		});
		stream.on("error", (error) => {
			console.log("stream end");
			console.log(error);
			stream.cancel();
			subscriber.error(error);
		});
		stream.on("end", () => {
			console.log("stream end");
			if (Boolean(restart)) {
				console.log("restart is set; emitting error to restart stream");
				subscriber.error();
			} else {
				subscriber.complete();
			}
		});
		// emit undefined to the subscriber to indicate that the stream has started
		const connectEvent: StreamLifecycleEvent = {
			_type: "StreamLifecycleEvent",
			event: "connect",
		};
		subscriber.next(connectEvent);
		stream.on("status", (status) => {
			console.log("stream status:", status);
		});
	});
}

export abstract class StreamManager<T> {
	protected constructor(streamBuilder: (metadata: Metadata) => StreamData<T>) {
		this.streamBuilder = streamBuilder;
	}

	private streamData: StreamData<T> | undefined;
	private readonly streamBuilder: (metadata: Metadata) => StreamData<T>;
	public open = (): Observable<T | StreamLifecycleEvent> => {
		console.warn("open pipe");
		// close the stream if it's already open
		this.close();
		return makeRequest<T | StreamLifecycleEvent>((metadata) => {
			this.streamData = this.streamBuilder(metadata);
			return streamToObservable(this.streamData!.stream, this.streamData!.restartStream);
		});
	};
	public close = () => {
		console.log("close pipe");
		if (this.streamData === undefined) {
			console.log("unnecessarily closing stream");
		}
		if (this.streamData !== undefined) {
			this.streamData.restartStream = false;
			this.streamData.stream.cancel();
		}
		this.streamData = undefined;
	};
}

namespace GrpcClient {
	export function prepareRequest<R>(
		handler: IGrpcRequestHandler<R>,
		skipUserCreate?: boolean,
		omitAuth?: boolean,
		transformer?: MonoTypeOperatorFunction<R>
	): Observable<R> {
		return makeRequest((metadata) => handler(metadata), skipUserCreate, omitAuth).pipe(
			transformer ? transformer : tap()
		);
	}

	export function prepareRequestWithPayload<R, T = void>(
		handler: IfVoid<T, IGrpcRequestHandler<R>, IGrpcRequestHandlerWithPayload<T, R>>,
		input: T,
		skipUserCreate?: boolean,
		omitAuth?: boolean,
		transformer?: MonoTypeOperatorFunction<R>
	): Observable<R> {
		return makeRequest((metadata) => handler(metadata, input), skipUserCreate, omitAuth).pipe(
			transformer ? transformer : tap()
		);
	}
}

export { GrpcClient as grpc };

export default GrpcClient;
