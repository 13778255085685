import { GetConversationsResponse } from "@somewear/api";
import { addContacts } from "@somewear/asset";
import { grpc, someGrpc } from "@somewear/grpc";
import { conversationActions } from "@somewear/messaging";
import type { IRequestPayloadAction, VoidableRequestPayload } from "@somewear/model";
import { actionSetEpicHandlerBuilder } from "@somewear/model";
import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import { filter, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";

import { createContactFromConversationResponse } from "./conversationsSlice";

const fetchConversationsEpic = actionSetEpicHandlerBuilder(conversationActions.fetch, () => {
	return grpc.prepareRequest(
		someGrpc.fetchConversations,
		undefined,
		undefined,
		catchError((e) => {
			// this results in silent errors
			console.error(e);
			const response = new GetConversationsResponse();
			response.setConversationsList([]);
			return of(response);
		})
	);
});

const createConversationsEpic = actionSetEpicHandlerBuilder(conversationActions.create, (payload) =>
	grpc.prepareRequestWithPayload(someGrpc.createConversation, payload.data)
);

const deleteConversationEpic = actionSetEpicHandlerBuilder(
	conversationActions.delete,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.deleteConversation, payload.data),
	{
		onFulfilled: "Successfully deleted conversation",
		onPending: "Deleting conversation",
		onRejected: "Failed to delete the conversation",
	}
);

export const conversationFetchToContactEpics: Epic = (action$, state$) => {
	return action$.pipe(
		filter(conversationActions.fetch.fulfilled.match),
		mergeMap((it) => {
			const conversations = it.payload.data.conversationsList;
			const contacts = conversations.mapNotNull((conversationResponse) => {
				if (conversationResponse.conversation === undefined) return undefined;
				return createContactFromConversationResponse(conversationResponse.conversation);
			});
			return [addContacts(contacts)];
		})
	);
};

export const conversationCreateToContactEpics: Epic = (action$, state$) => {
	return action$.pipe(
		filter(conversationActions.create.fulfilled.match),
		mergeMap((it) => {
			const result = it.payload.data;
			if (result.conversation === undefined) return [];

			return [addContacts([createContactFromConversationResponse(result.conversation)])];
		})
	);
};

export const conversationEpics = combineEpics<
	IRequestPayloadAction<VoidableRequestPayload>,
	IRequestPayloadAction<unknown>,
	any
>(
	createConversationsEpic,
	fetchConversationsEpic,
	deleteConversationEpic,
	conversationFetchToContactEpics,
	conversationCreateToContactEpics
);
