import {
	selectAllUserContacts,
	selectContactById,
	selectUnarchivedContactEntities,
} from "@somewear/asset";
import { selectActiveIdentityId, selectActiveWorkspaceId } from "@somewear/auth";
import { selectAllFiles } from "@somewear/files";
import type { MessagingState } from "@somewear/messaging";
import {
	getMessageTimestamp,
	isFile,
	isWorkspaceConversation,
	messageHasTimestamp,
	selectAllConversations,
	selectAllMessages,
	selectConversationByKey,
	selectConversationEntities,
} from "@somewear/messaging";
import { PERSONAL_WORKSPACE_ID } from "@somewear/workspace";
import { workspaceInfoSelector } from "@web/app/appSelectors";
import { createDeepEqualSelector } from "@web/common/utils";
import groupBy from "lodash/groupBy";
import moment from "moment";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";

import Config from "../config/Config";
import type { ConversationPreview } from "./MessagingSidebarView";

/**
const messagesSelector = (
	state: RootState,
	props: ConversationOrWorkspace
): DataMap<MessageResponseWithState> => {
	if (props.conversationId) {
		return state.messaging.messages[props.conversationId];
	} else {
		return state.messaging.workspaceMessages[props.workspaceId!];
	}
};

export const sortMessagesAsc = createSelector([messagesSelector], (messages) => {
	if (messages === undefined) return [];
	return Object.values(messages.data).sort((a, b) => {
		let aSeconds = a.timestamp!.seconds;
		let bSeconds = b.timestamp!.seconds;
		return aSeconds - bSeconds;
	});
});
 **/

export const selectNewConversationRecipient = (state: MessagingState) => {
	return state.messaging.recipient;
};

export const selectAllMessagesAndFiles = createSelector(
	selectAllMessages,
	selectAllFiles,
	(messages, files) => {
		const messagesAndFiles = [...messages, ...files];

		return messagesAndFiles.sort((a, b) => {
			return getMessageTimestamp(a).seconds - getMessageTimestamp(b).seconds;
		});
	}
);

const readTimestampsSelector = (state: MessagingState) => {
	return state.messaging.readTimestamps;
};

const selectAllConversationPreviews = createSelector(
	[
		selectAllMessagesAndFiles,
		selectAllConversations,
		selectUnarchivedContactEntities,
		selectActiveWorkspaceId,
		workspaceInfoSelector,
		readTimestampsSelector,
	],
	(messages, conversations, contacts, activeWorkspaceId, workspaceInfo, readTimestamps) => {
		return conversations
			.map((conversation) => {
				if (
					isWorkspaceConversation(conversation) &&
					conversation.workspaceId // &&
					// conversation.workspaceId === activeWorkspaceId
				) {
					const latestMessage = messages
						.filter((message) => message.workspaceId === conversation.workspaceId)
						.lastOrUndefined();
					return {
						id: "",
						contact: workspaceInfo[conversation.workspaceId],
						isWorkspace: true,
						mostRecentMessage: latestMessage,
						workspaceId: conversation.workspaceId,
						readTimestamp: readTimestamps.workspaces[conversation.workspaceId],
					} as ConversationPreview;
				} else if (!isWorkspaceConversation(conversation)) {
					const contact = contacts[conversation.participantId!];
					const latestMessage = messages
						.filter(
							(message) =>
								!isFile(message) &&
								!message.workspaceId &&
								message.conversationId === conversation.id
						)
						.lastOrUndefined();

					return {
						id: conversation.id,
						contact: contact,
						participantId: conversation.participantId,
						mostRecentMessage: latestMessage,
						workspaceId: undefined,
						readTimestamp: readTimestamps.conversations[conversation.id],
					} as ConversationPreview;
				}
				return undefined;
			})
			.filter((conv) => conv !== undefined) as ConversationPreview[];
	}
);

/**
const conversationsPreviewSelector = (state: RootState): ConversationPreview[] => {
	let conversations: ConversationPreview[] = Object.keys(state.messaging.conversations).map(
		(conversationId) => {
			return {
				id: conversationId,
				contact: state.contacts.entities[state.messaging.conversations[conversationId]]!,
				mostRecentMessage: sortMessagesAsc(state, {
					conversationId: conversationId,
				}).lastOrUndefined(),
				workspaceId: undefined,
				readTimestamp: state.messaging.readTimestamps.conversations[conversationId],
			};
		}
	);
	let activeWorkspace = state.app.activeTeamWorkspace;
	if (activeWorkspace) {
		conversations.push({
			id: "",
			contact: state.app.workspaceInfo[activeWorkspace.id],
			mostRecentMessage: sortMessagesAsc(state, {
				workspaceId: activeWorkspace.id,
			}).lastOrUndefined(),
			workspaceId: activeWorkspace.id,
			readTimestamp: state.messaging.readTimestamps.workspaces[activeWorkspace.id],
		});
	}
	return conversations;
};
**/

export const sortConversationsDesc = createDeepEqualSelector(
	[selectAllConversationPreviews],
	(previews) => {
		return previews.sort((a, b) => {
			if (a.workspaceId !== undefined && b.workspaceId === undefined) return -1;
			else if (b.workspaceId !== undefined && a.workspaceId === undefined) return 1;
			else if (
				!messageHasTimestamp(a.mostRecentMessage) &&
				messageHasTimestamp(b.mostRecentMessage)
			)
				return 1;
			else if (
				messageHasTimestamp(a.mostRecentMessage) &&
				!messageHasTimestamp(b.mostRecentMessage)
			)
				return -1;
			else if (
				!messageHasTimestamp(a.mostRecentMessage) &&
				!messageHasTimestamp(b.mostRecentMessage)
			)
				return a.id.localeCompare(b.id);
			else {
				const aSeconds = getMessageTimestamp(a.mostRecentMessage!)?.seconds;
				const bSeconds = getMessageTimestamp(b.mostRecentMessage!)?.seconds;
				return bSeconds - aSeconds;
			}
		});
	}
);

export const selectMessagesByConversationKey = createSelector(
	[selectAllMessagesAndFiles, selectConversationByKey],
	(messages, conversation) => {
		if (isWorkspaceConversation(conversation) && conversation?.workspaceId) {
			return messages.filter((message) => message.workspaceId === conversation.workspaceId);
		} else if (conversation?.id) {
			return messages.filter(
				(message) => !isFile(message) && message.conversationId === conversation.id
			);
		}
		throw Error("A valid conversation key must be provided");
	}
);

export const selectMessagesByConversationKeyGroupedByDate = createSelector(
	selectMessagesByConversationKey,
	(messages) => {
		return groupBy(messages, (message) =>
			moment(Date.DateFromTimestamp(getMessageTimestamp(message)))
				.startOf("day")
				.unix()
		);
	}
);

export const selectConversationMessages = createCachedSelector(
	selectAllMessages,
	selectConversationByKey,
	(messages, conversation) => {
		if (conversation === undefined) return;

		return groupBy(messages, "conversationId")[conversation.id];
	}
)((_state_, conversationId) => conversationId);

export const selectWorkspaceMessages = createCachedSelector(
	selectAllMessagesAndFiles,
	selectConversationByKey,
	(messages, conversation) => {
		if (conversation?.workspaceId === undefined) return;

		return groupBy(messages, "workspaceId")[conversation.workspaceId];
	}
)((_state_, workspaceId) => workspaceId);

export const selectWorkspaceFiles = createCachedSelector(
	selectAllFiles,
	selectConversationByKey,
	(files, conversation) => {
		if (conversation?.workspaceId === undefined) return;

		return groupBy(files, "workspaceId")[conversation.workspaceId];
	}
)((_state_, workspaceId) => workspaceId);

export const selectWorkspaceFilesSortedDesc = createSelector(selectWorkspaceFiles, (files) => {
	return files?.sort((a, b) =>
		(a.uploadedTimestamp ?? 0) > (b.uploadedTimestamp ?? 0) ? 1 : -1
	);
});

export const selectSelfAccountId = createSelector(
	[selectAllUserContacts, selectActiveIdentityId, selectConversationByKey],
	(contacts, myIdentityId, conversation) => {
		if (conversation?.id === undefined) return undefined;

		if (conversation.participantId?.isNotEmpty()) {
			const target = contacts?.find((contact) => contact.id === conversation.participantId);
			if (target?.workspaceId?.isNotEmpty()) {
				const self = contacts.find(
					(contact) =>
						contact.identityId === myIdentityId &&
						contact.workspaceId === target.workspaceId
				);
				return self?.id;
			} else {
				const self = contacts.find(
					(contact) =>
						contact.identityId === myIdentityId &&
						contact.workspaceId === PERSONAL_WORKSPACE_ID
				);
				return self?.id;
			}
		} else if (conversation.workspaceId?.isNotEmpty()) {
			const myAccount = contacts
				.filter((user) => user.workspaceId === conversation.workspaceId)
				.find((user) => user.identityId === myIdentityId);
			return myAccount?.id;
		}
		return;
	}
);

const selectIsConversationsLoaded = (state: MessagingState) => {
	return state.messaging.conversationsLoaded;
};

const selectIsTimestampsLoaded = (state: MessagingState) => {
	if (!Config.firebase.enable) return true;
	return state.messaging.timestampsLoaded;
};

export const selectIsMessagingLoaded = createSelector(
	[selectIsConversationsLoaded, selectIsTimestampsLoaded],
	(conversationsLoaded, timestampsLoaded) => {
		return conversationsLoaded && timestampsLoaded;
	}
);

export const selectSelectedConversationKey = (state: MessagingState) =>
	state.messaging.selectedConversationKey;

export const selectSelectedConversation = createSelector(
	[selectSelectedConversationKey, selectConversationEntities],
	(key, entities) => {
		if (key === undefined) return undefined;
		return entities[key];
	}
);

export const selectWorkspaceSidebarView = (state: MessagingState) =>
	state.messaging.workspaceSidebarView;

export const selectIsSelf = createSelector(
	[selectActiveIdentityId, selectContactById],
	(identityId, contact) => {
		return contact?.identityId === identityId;
	}
);

export const selectMessagingFilteredWorkspaceId = (state: MessagingState) => {
	return state.messaging.filteredWorkspaceId;
};

export const selectContactIdsFilteredByMessagingWorkspaceId = createSelector(
	[selectMessagingFilteredWorkspaceId, selectAllUserContacts],
	(filteredWorkspaceId, contacts) => {
		return contacts
			.filter((contact) => contact.workspaceId === filteredWorkspaceId)
			.mapNotNull((contact) => contact.id);
	}
);
