import type { EntityState } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RouteResponse } from "@somewear/api";
import { emitAssetAccountsDeleted } from "@somewear/asset";
import type { ITrackingRoute } from "@somewear/model";
import { clearWorkspace } from "@somewear/workspace";
import type { RootState } from "@web/app/rootReducer";
import { getLocationsFulfilled } from "@web/tracking/trackingActions";

import { trackingLocationActions } from "../locations/trackingLocationActions";
import { trackingRouteActions } from "./trackingRouteActions";

const adapter = createEntityAdapter<ITrackingRoute>({
	selectId: (route) => route.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllTrackingRoutes,
	selectById: selectTrackingRoutesById,
	selectEntities: selectTrackingRouteEntities,
} = adapter.getSelectors((state: RootState) => state.trackingRoutes);

const upsertMany = (state: EntityState<ITrackingRoute>, routes: ITrackingRoute[]) => {
	const _routes = routes.map((route) => {
		const _route = { ...route } as RouteResponse.AsObject;
		(_route as ITrackingRoute).timestamp = _route.location?.timestamp;
		delete _route.owner;
		delete _route.location;
		return _route;
	});
	adapter.upsertMany(state, _routes);
};

const trackingRoutesSlice = createSlice({
	name: "routes",
	initialState: adapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(trackingRouteActions.getLive.fulfilled, (state, action) => {
			upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingRouteActions.getLastKnown.fulfilled, (state, action) => {
			upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingRouteActions.getRoutes.fulfilled, (state, action) => {
			upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingLocationActions.shareLocation.fulfilled, (state, action) => {
			const routes = action.payload.data.map((location) => {
				const route: ITrackingRoute = {
					id: location.routeId,
					name: "",
					viewersList: [],
					type: RouteResponse.Type.TRACKING,
					isSelf: true,
					notes: "",
					ownerId: location.userId,
					workspaceIdsList: [],
				};
				return route;
			});
			upsertMany(state, routes);
		});
		builder.addCase(getLocationsFulfilled, (state, action) => {
			const routes = action.payload.mapNotNull((location) => {
				const route: ITrackingRoute = {
					id: location.routeId,
					name: "",
					viewersList: [],
					type: RouteResponse.Type.TRACKING,
					isSelf: true,
					notes: "",
					ownerId: location.userId,
					workspaceIdsList: [],
				};
				if (adapter.getSelectors().selectById(state, route.id) === undefined) return route;
				return;
			});
			upsertMany(state, routes);
		});
		builder.addCase(clearWorkspace, (state, action) => {
			const routes = adapter.getSelectors().selectAll(state);
			// note: we don't support sharing with multiple workspaces right now, this might be over aggressively removing routes
			const workspaceRoutes = routes.filter((it) =>
				it.workspaceIdsList.includes(action.payload)
			);
			adapter.removeMany(
				state,
				workspaceRoutes.map((it) => it.id)
			);
		});
		builder.addCase(emitAssetAccountsDeleted, (state, action) => {
			// when an account is deleted, delete the related data
			const deletedAccountIds = action.payload.map((it) => it.id);
			const deletedItems = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => deletedAccountIds.includes(it.ownerId));
			adapter.removeMany(
				state,
				deletedItems.map((it) => it.id)
			);
		});
	},
});

export default trackingRoutesSlice;
