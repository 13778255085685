import type { Dictionary } from "@reduxjs/toolkit";
import type { IdentityRecord } from "@somewear/api";
import { OrganizationRole } from "@somewear/api";
import type { IWorkspaceIntegrationAsset } from "@somewear/asset";
import {
	emitBulkUpsertAccount,
	emitBulkUpsertIdentity,
	formatIntegration,
	getIntegrationType,
	selectIdentityById,
} from "@somewear/asset";
import { selectActiveWorkspaceId } from "@somewear/auth";
import { grpc, someGrpc } from "@somewear/grpc";
import type { IIdentity, IIntegrationIdentity } from "@somewear/model";
import { actionSetEpicHandlerBuilder } from "@somewear/model";
import { emitOrganizationWorkspacesReceived, updateWorkspaceAccess } from "@somewear/workspace";
import type { Action } from "redux";
import { combineEpics, type Epic } from "redux-observable";
import { filter, map, merge, mergeMap } from "rxjs";

import { organizationMemberActions } from "./member";
import { organizationActions } from "./organization.actions";

const createOrganizationWorkspaceEpic = actionSetEpicHandlerBuilder(
	organizationActions.createWorkspace,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.createOrganizationWorkspace, payload.data),
	{
		onFulfilled: "Successfully created the workspace",
		onRejected: "Failed to create workspace",
		onPending: "Creating workspace...",
	}
);

const createIntegrationEpic = actionSetEpicHandlerBuilder(
	organizationActions.createIntegration,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.createOrganizationIntegration, payload.data)
);

const createTakIntegrationEpic = actionSetEpicHandlerBuilder(
	organizationActions.createTakIntegration,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.createOrganizationTakIntegration, payload.data)
);

const removeIntegrationEpic = actionSetEpicHandlerBuilder(
	organizationActions.removeIntegration,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.removeOrganizationIntegration, payload.data)
);

const configureIntegrationEpic = actionSetEpicHandlerBuilder(
	organizationActions.configureIntegration,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.configureIntegration, payload.data)
);

const sendCotToTakServerEpic = actionSetEpicHandlerBuilder(
	organizationActions.sendCotToTakServer,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.sendCotToTakServer, payload.data)
);

const fetchIntegrationAccountsEpic = actionSetEpicHandlerBuilder(
	organizationActions.getIntegrationAccounts,
	() => grpc.prepareRequest(someGrpc.fetchIntegrationAccounts)
);

const createResourceEpic = actionSetEpicHandlerBuilder(
	organizationActions.createResource,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.createOrganizationResource, payload.data)
);

const getOrganizationLicenseEpic = actionSetEpicHandlerBuilder(organizationActions.getLicense, () =>
	grpc.prepareRequest(someGrpc.fetchOrganizationLicenseSummary)
);

const getOrganizationWorkspacesEpic = actionSetEpicHandlerBuilder(
	organizationActions.getWorkspaces,
	() => grpc.prepareRequest(someGrpc.fetchOrganizationWorkspaces)
);

const getOrganizationIdentitiesEpic = actionSetEpicHandlerBuilder(
	organizationActions.getAssets,
	() => grpc.prepareRequest(someGrpc.fetchOrganizationIdentities)
);

const fetchOrganizationDeviceUsageEpic = actionSetEpicHandlerBuilder(
	organizationActions.getDeviceUsage,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.fetchOrganizationDeviceUsage, payload.data)
);

const fetchAllDeviceUsageByOrganizationEpic = actionSetEpicHandlerBuilder(
	organizationActions.getAllDeviceUsage,
	() => grpc.prepareRequest(someGrpc.fetchAllDeviceUsageByOrganization)
);

const fetchBillingInfoEpic = actionSetEpicHandlerBuilder(organizationActions.getBillingInfo, () =>
	grpc.prepareRequest(someGrpc.fetchOrganizationBillingInfo)
);

const joinOrganizationWorkspaceEpic = actionSetEpicHandlerBuilder(
	organizationActions.joinWorkspace,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.joinOrganizationWorkspace, payload.data)
);

const removeOrganizationWorkspaceEpic = actionSetEpicHandlerBuilder(
	organizationActions.removeWorkspace,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.archiveOrganizationWorkspace, payload.data)
);

const deleteOrganizationWorkspaceEpic = actionSetEpicHandlerBuilder(
	organizationActions.deleteWorkspace,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.deleteOrganizationWorkspace, payload.data)
);

const confirmOrganizationClaimCodeEpic = actionSetEpicHandlerBuilder(
	organizationActions.confirmCode,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.confirmOrganizationClaimCode, payload.data)
);

const claimOrganizationEpic = actionSetEpicHandlerBuilder(organizationActions.claim, (payload) =>
	grpc.prepareRequestWithPayload(someGrpc.claimOrganization, payload.data)
);

const updateOrganizationAssetPasswordEpic = actionSetEpicHandlerBuilder(
	organizationActions.changeUserPassword,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.updateOrganizationAssetPassword, payload.data)
);

const organizationWorkspacesRemovedEpic: Epic<Action<unknown>> = (action$, state$) => {
	return merge([
		action$.pipe(filter(organizationActions.removeWorkspace.fulfilled.match)),
		action$.pipe(filter(organizationActions.deleteWorkspace.fulfilled.match)),
	]).pipe(
		mergeMap((obs$) => {
			return obs$.pipe(
				mergeMap((action) => {
					const activeWorkspaceId = selectActiveWorkspaceId(state$.value);
					return [updateWorkspaceAccess(action.payload.data)];
				})
			);
		})
	);
};

const receivedOrganizationWorkspacesEpic: Epic<Action<unknown>> = (action$, state$) => {
	return action$.pipe(
		filter(organizationActions.getWorkspaces.fulfilled.match),
		mergeMap((action) => {
			return [emitOrganizationWorkspacesReceived(action.payload.data.workspacesList)];
		})
	);
};

const upsertOrganizationIntegrationAccountsEpic: Epic<Action<unknown>> = (action$, state$) => {
	return action$.pipe(
		filter(organizationActions.getIntegrationAccounts.fulfilled.match),
		map((action) => {
			const accounts = action.payload.data.accountsList;
			if (accounts === undefined) return [];

			const integrationAccounts: IWorkspaceIntegrationAsset[] = accounts.mapNotNull((it) => {
				if (it.account === undefined) return undefined;
				return {
					...it.account,
					inboundEnabled: it.inboundEnabled,
					outboundEnabled: it.outboundEnabled,
				};
			});
			return integrationAccounts;
		}),
		mergeMap((it) => {
			return [emitBulkUpsertAccount(it)];
		})
	);
};

const upsertConfiguredOrganizationIntegrationAccountsEpic: Epic<Action<unknown>> = (
	action$,
	state$
) => {
	return action$.pipe(
		filter(organizationActions.configureIntegration.fulfilled.match),
		map((action) => {
			const accounts = action.payload.data.accountsList;
			if (accounts === undefined) return [];

			const integrationAccounts: IWorkspaceIntegrationAsset[] = accounts.mapNotNull((it) => {
				if (it.account === undefined) return undefined;
				return {
					...it.account,
					inboundEnabled: it.inboundEnabled,
					outboundEnabled: it.outboundEnabled,
				};
			});
			return integrationAccounts;
		}),
		mergeMap((it) => {
			return [emitBulkUpsertAccount(it)];
		})
	);
};

const transformerEpics: Epic<Action<unknown>>[] = [];

transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationActions.getAssets.fulfilled.match),
		mergeMap((action) => {
			const identities = action.payload.data.identitiesList;
			return [emitBulkUpsertIdentity(identities)];
		})
	);
});

transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationMemberActions.getMembers.fulfilled.match),
		mergeMap((action) => {
			const identities: IdentityRecord.AsObject[] = action.payload.data.membersList
				.map((member) => {
					const identity: IIdentity | undefined = member.identity;
					if (identity !== undefined) {
						identity.organizationId = member.organizationId;
						identity.organizationRole = member.role;
						identity.joinedOrganizationDate = member.createdDate;
					}
					return identity;
				})
				.filter((identity) => identity !== undefined) as IdentityRecord.AsObject[];
			return [emitBulkUpsertIdentity(identities)];
		})
	);
});

transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationMemberActions.getIntegrations.fulfilled.match),
		mergeMap((action) => {
			const identities: IdentityRecord.AsObject[] = action.payload.data.integrationsOldList
				.map((member) => {
					const identity: IIdentity | undefined = member.identity;
					if (identity !== undefined) {
						identity.joinedOrganizationDate = identity.createdDate;

						const integrationData = action.payload.data.integrationsList.find(
							(item) => item.identity?.id === identity.id
						);

						identity.integrationData = {
							webhook: integrationData?.webhook,
							takServer: integrationData?.takServer,
						};
					}

					return identity;
				})
				.filter((identity) => identity !== undefined) as IdentityRecord.AsObject[];
			return [emitBulkUpsertIdentity(identities)];
		})
	);
});

transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationMemberActions.assignRole.fulfilled.match),
		mergeMap((action) => {
			const member = action.payload.data.member;
			if (member?.identity?.id === undefined) return [];

			const upsertIdentity = selectIdentityById(state$.value, member.identity.id);
			if (upsertIdentity === undefined) return [];

			const upsertIdentityCopy = { ...upsertIdentity };
			upsertIdentityCopy.organizationRole = member.role;
			return [emitBulkUpsertIdentity([upsertIdentityCopy])];
		})
	);
});
transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationMemberActions.addMembers.fulfilled.match),
		mergeMap((action) => {
			const identityDict: Dictionary<IIdentity> = {};
			action.payload.data.identityRecordsList.forEach((record) => {
				identityDict[record.id] = record;
			});

			const identities: IIdentity[] = action.payload.data.membershipsList.mapNotNull(
				(member) => {
					const identity: IIdentity | undefined = identityDict[member.identityId];
					if (identity !== undefined) {
						identity.organizationRole = member.role;
					}
					return identity;
				}
			);
			return [emitBulkUpsertIdentity(identities)];
		})
	);
});
transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationMemberActions.membershipChanged.fulfilled.match),
		mergeMap((action) => {
			const response = action.payload.data;
			if (response.role === OrganizationRole.ORGANIZATIONROLENONE) {
				const identity: IIdentity = response.identity!;
				identity.organizationRole = response.role;
				identity.organizationId = "";
				return [emitBulkUpsertIdentity([identity])];
			} else {
				const identity: IIdentity = response.identity!;
				identity.organizationRole = response.role;
				return [emitBulkUpsertIdentity([identity])];
			}
		})
	);
});
transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationActions.createResource.fulfilled.match),
		mergeMap((action) => {
			if (action.payload.data.identity === undefined) return [];
			return [emitBulkUpsertIdentity([action.payload.data.identity])];
		})
	);
});
transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationActions.createIntegration.fulfilled.match),
		mergeMap((action) => {
			const { integration } = action.payload.data;

			if (integration?.identity === undefined) return [];

			const identity = {
				...integration.identity,
				integrationType: getIntegrationType(integration),
				inboundEnabled: false,
				outboundEnabled: true,
			} as IIntegrationIdentity;

			return [emitBulkUpsertIdentity([identity])];
		})
	);
});

transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationActions.getIntegrationAccounts.fulfilled.match),
		mergeMap((action) => {
			const identities = action.payload.data.identitiesList;

			if (identities === undefined) return [];

			const integrationIdentities = identities.mapNotNull(formatIntegration);

			return [emitBulkUpsertIdentity(integrationIdentities)];
		})
	);
});
transformerEpics.push((action$, state$) => {
	return action$.pipe(
		filter(organizationActions.configureIntegration.fulfilled.match),
		mergeMap((action) => {
			const identities = action.payload.data.identitiesList;
			if (identities === undefined) return [];

			const integrationIdentities = identities.mapNotNull(formatIntegration);

			return [emitBulkUpsertIdentity(integrationIdentities)];
		})
	);
});

export const organizationEpics = combineEpics<any, any, any>(
	createOrganizationWorkspaceEpic,
	createIntegrationEpic,
	createTakIntegrationEpic,
	createResourceEpic,
	removeIntegrationEpic,
	configureIntegrationEpic,
	fetchIntegrationAccountsEpic,
	getOrganizationLicenseEpic,
	getOrganizationWorkspacesEpic,
	getOrganizationIdentitiesEpic,
	joinOrganizationWorkspaceEpic,
	getOrganizationIdentitiesEpic,
	fetchOrganizationDeviceUsageEpic,
	fetchAllDeviceUsageByOrganizationEpic,
	removeOrganizationWorkspaceEpic,
	fetchBillingInfoEpic,
	confirmOrganizationClaimCodeEpic,
	claimOrganizationEpic,
	updateOrganizationAssetPasswordEpic,
	sendCotToTakServerEpic,
	deleteOrganizationWorkspaceEpic,
	organizationWorkspacesRemovedEpic,
	receivedOrganizationWorkspacesEpic,
	upsertOrganizationIntegrationAccountsEpic,
	upsertConfiguredOrganizationIntegrationAccountsEpic,
	...transformerEpics
);
