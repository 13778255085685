import { fromByteArray, toByteArray } from "base64-js";
import type { Message } from "google-protobuf";

/**
 * Class that wraps LocalStorage for storing data tied to an authenticated user (UserIdentity). Supports storing more
 * than just string types.
 */
export class IdentityStorage<T> {
	/**
	 * Every IdentityStorage instance reserves a space in LocalStorage with the given storeKey.
	 * @private
	 */
	private readonly storeKey: string;
	private cache: any = {};
	private readonly mapper: StorageMapper<T>;

	static withProto<P extends Message>(
		name: string,
		builder: (data: Uint8Array) => P
	): IdentityStorage<P> {
		const protoMapper = new StorageMapper<P>(
			(value) => {
				const data = value.serializeBinary();
				return fromByteArray(data);
			},
			(stored) => {
				const buf = toByteArray(stored);
				const bufView = new Uint8Array(buf);
				return builder(bufView);
			}
		);
		return new IdentityStorage(name, protoMapper);
	}

	static withString(name: string): IdentityStorage<string> {
		const stringMapper = new StorageMapper<string>(
			(value) => {
				return value;
			},
			(stored) => {
				return stored;
			}
		);
		return new IdentityStorage(name, stringMapper);
	}

	constructor(storeKey: string, mapper: StorageMapper<T>) {
		this.storeKey = storeKey;
		this.mapper = mapper;
	}

	putValue(uid: string, value?: T) {
		// Pull from storage
		let stored = this.getStoreObject();

		// handle deletes
		if (value === undefined) {
			this.removeValueFromStore(uid, stored);
			return;
		}

		if (stored === undefined) {
			stored = {};
		}

		// Cache unmapped instance
		this.cache[uid] = value;

		// Map and store
		stored[uid] = this.mapper.putValue(value);
		this.setStoreObject(stored);
	}

	getValue(uid: string): T | undefined {
		// Check cache first
		const cached = this.cache[uid];
		if (cached != null) {
			return cached;
		}

		// Pull from storage
		const stored = this.getStoreObject();
		if (stored === undefined) {
			return;
		}

		// Get stored value from subkey
		const storedValue = stored[uid];
		if (storedValue === undefined) {
			return;
		}

		// Map to original value and update cache
		const mapped = this.mapper.getValue(storedValue);
		this.cache[uid] = mapped;
		return mapped;
	}

	removeValue(key: string) {
		const stored = this.getStoreObject();
		if (stored === undefined) {
			return;
		}
		this.removeValueFromStore(key, stored);
	}

	private removeValueFromStore(key: string, stored: any | undefined) {
		delete this.cache[key];

		if (stored === undefined) return;
		delete stored[key];

		// Remove from storage if empty
		if (Object.keys(stored).isEmpty()) {
			localStorage.removeItem(this.storeKey);
		} else {
			// Update store if item was removed but stored isn't empty.
			this.setStoreObject(stored);
		}
	}

	private setStoreObject(obj: any) {
		const json = JSON.stringify(obj);
		localStorage.setItem(this.storeKey, json);
	}

	private getStoreObject(): any | undefined {
		const result = localStorage.getItem(this.storeKey);
		if (result === null) return undefined;
		return JSON.parse(result);
	}
}

class StorageMapper<T> {
	putValue: (value: T) => string;
	getValue: (stored: string) => T;

	constructor(putValue: (value: T) => string, getValue: (stored: string) => T) {
		this.putValue = putValue;
		this.getValue = getValue;
	}
}
