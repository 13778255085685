import {
	BasicCredentials,
	CoordinateDto,
	CreateBeamAuthRequest,
	CreateOrganizationIntegrationRequest,
	CreateShapeRequest,
	Geofence,
	IdentityRecord,
	IntegrationResponse,
	JsonCredentials,
	Shape,
	UpdateShapeRequest,
} from "@somewear/api";
import type { TAKServerIntegrationRequest, WebhookIntegrationRequest } from "@somewear/model";

declare module "@somewear/api" {
	interface CreateShapeRequest {
		assignShape(shape: Shape.AsObject | Shape): void;
	}

	interface UpdateShapeRequest {
		assignShape(shape: Shape.AsObject | Shape): void;
	}

	interface CreateOrganizationIntegrationRequest {
		assignTakIntegration(payload: TAKServerIntegrationRequest): void;
		assignWebhookIntegration(payload: WebhookIntegrationRequest): void;
	}
}

function geofenceFromObject(payload: Geofence.AsObject): Geofence {
	const geofence = new Geofence();
	geofence.setEnabled(payload.enabled);
	geofence.setNotificationTarget(payload.notificationTarget);
	geofence.setNotificationStrategy(payload.notificationStrategy);
	return geofence;
}

function shapeFromObject(payload: Shape.AsObject): Shape {
	const shape = new Shape();
	shape.setId(payload.id);
	shape.setName(payload.name);
	shape.setNotes(payload.notes);
	shape.setWorkspaceId(payload.workspaceId);

	if (payload.circle !== undefined) {
		const circle = new Shape.Circle();
		const center = new CoordinateDto();
		center.setLatitude(payload.circle.center!.latitude);
		center.setLongitude(payload.circle.center!.longitude);
		circle.setCenter(center);

		const radius = Math.round(payload.circle.radius * 1000);

		circle.setRadius(radius);
		shape.setCircle(circle);
	}

	if (payload.polygon !== undefined) {
		const polygon = new Shape.Polygon();
		polygon.setPerimeterList(
			payload.polygon.perimeterList.map((it) => {
				const coordinate = new CoordinateDto();
				coordinate.setLatitude(it.latitude);
				coordinate.setLongitude(it.longitude);
				return coordinate;
			})
		);
		polygon.setType(payload.polygon.type);
		shape.setPolygon(polygon);
	}

	if (payload.geofence?.enabled === true) {
		shape.setGeofence(geofenceFromObject(payload.geofence));
	} else {
		const geofence = new Geofence();
		shape.setGeofence(geofence);
	}
	return shape;
}

CreateOrganizationIntegrationRequest.prototype.assignTakIntegration = function (
	payload: TAKServerIntegrationRequest
) {
	const integration = new IntegrationResponse();
	const takServer = new IntegrationResponse.TakServer();
	const identity = new IdentityRecord();
	identity.setFullName(payload.name);
	integration.setIdentity(identity);

	takServer.setHost(payload.host);
	takServer.setPort(payload.port);
	takServer.setClientCert(payload.clientCert);
	takServer.setClientCertPassword(payload.clientCertPassword);
	takServer.setServerCert(payload.serverCert);
	takServer.setServerCertPassword(payload.serverCertPassword);

	integration.setTakServer(takServer);
	this.setIntegration(integration);

	if (payload.beamAuth !== undefined) {
		const beamAuth = new CreateBeamAuthRequest();
		beamAuth.setUsername(payload.beamAuth.username);
		beamAuth.setPassword(payload.beamAuth.password);
		this.setBeamAuth(beamAuth);
	}
};

CreateOrganizationIntegrationRequest.prototype.assignWebhookIntegration = function (
	payload: WebhookIntegrationRequest
) {
	const integration = new IntegrationResponse();
	const webhook = new IntegrationResponse.Webhook();
	const identity = new IdentityRecord();
	identity.setFullName(payload.name);
	integration.setIdentity(identity);

	webhook.setUrl(payload.url);

	if (payload.basic?.password && payload.basic?.username) {
		const basicAuth = new BasicCredentials();
		basicAuth.setUsername(payload.basic.username);
		basicAuth.setPassword(payload.basic.password);

		webhook.setBasic(basicAuth);
	}

	if (payload.json?.json) {
		const jsonAuth = new JsonCredentials();
		jsonAuth.setJson(payload.json.json);

		webhook.setJson(jsonAuth);
	}

	integration.setWebhook(webhook);
	this.setIntegration(integration);
};

CreateShapeRequest.prototype.assignShape = function (payload: Shape.AsObject | Shape | undefined) {
	if (payload instanceof Shape || payload === undefined) {
		this.setShape(payload);
	} else {
		this.setShape(shapeFromObject(payload));
	}
};

UpdateShapeRequest.prototype.assignShape = function (payload: Shape.AsObject | Shape | undefined) {
	if (payload instanceof Shape || payload === undefined) {
		this.setShape(payload);
	} else {
		this.setShape(shapeFromObject(payload));
	}
};

export {};
