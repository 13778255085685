import {
	notificationsSlice,
	SomewearNotificationStatus,
	useSelectNotificationById,
} from "@somewear/notification";
import { CloseIconComponent, RenderIf } from "@somewear/ui";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
	AlertIconOutlinedComponent,
	AutoRenewIconComponent,
	CheckCircleIconComponent,
} from "../icons";

type Props = {
	requestId: string;
	onClose?: () => void;
};

export const ToastComponent: React.FC<Props> = (props) => {
	const [visible, setVisibility] = useState(true);
	const [closed, setClosed] = useState(false);
	const [onTimeout, setOnTimeout] = useState<NodeJS.Timeout | undefined>();
	const dispatch = useDispatch();

	const notification = useSelectNotificationById(props.requestId);

	const isPending = useCallback(
		() => notification?.status === SomewearNotificationStatus.pending,
		[notification?.status]
	);

	const isErrored = useCallback(
		() => notification?.status === SomewearNotificationStatus.error,
		[notification?.status]
	);

	const isSuccess = useCallback(
		() => notification?.status === SomewearNotificationStatus.success,
		[notification?.status]
	);

	const afterHide = useCallback(() => {
		clearTimeout(onTimeout!);
		dispatch(notificationsSlice.actions.removeNotificationById(props.requestId));
	}, [dispatch, onTimeout, props.requestId]);

	// only execute once (visible = safety) if auto remove is enabled
	useEffect(() => {
		let timeout: NodeJS.Timeout | undefined;
		if (notification?.autoRemove && visible) {
			timeout = setTimeout(() => {
				setVisibility(false);
			}, 5000);
			setOnTimeout(timeout);
		}

		return () => {
			if (timeout !== undefined) {
				clearTimeout(timeout);
				setOnTimeout(undefined);
			}
		};
	}, [visible, notification?.autoRemove]);

	// only execute once (close = safety)
	useEffect(() => {
		if (!visible && !closed) {
			setClosed(true);
			clearTimeout(onTimeout!);
			setOnTimeout(
				setTimeout(() => {
					afterHide();
				}, 500)
			);
		}

		return () => {
			if (onTimeout !== undefined) {
				clearTimeout(onTimeout);
			}
		};
	}, [visible, closed, onTimeout, afterHide]);

	return notification?.message === undefined ? (
		<></>
	) : (
		<RenderIf condition={Boolean(notification.message.isNotEmpty())}>
			<Container visible={visible}>
				<RenderIf condition={isPending()}>
					<UploadingIcon />
				</RenderIf>
				<RenderIf condition={isSuccess()}>
					<UploadCompleteIcon />
				</RenderIf>
				<RenderIf condition={isErrored()}>
					<UploadErrorIcon fill="#f45a14" />
				</RenderIf>
				<div className="flex-auto">
					<Message>{notification.message}</Message>
					<RenderIf condition={Boolean(notification.details)}>
						<Details>{notification.details}</Details>
					</RenderIf>
				</div>

				<RenderIf condition={!isPending()}>
					<ClickTarget>
						<CloseIconComponent
							className="bubble-component-close-icon"
							onClick={() => {
								setVisibility(false);
							}}
						/>
					</ClickTarget>
				</RenderIf>
			</Container>
		</RenderIf>
	);
};

const Container = styled.div<{ visible: boolean }>`
	background-color: #000;
	border: 1px solid #000;
	border-radius: 2px;
	display: flex;
	flex-flow: row nowrap;
	font-size: 12px;
	min-height: 36px;
	margin-top: ${(props) => (props.visible ? "0" : "-32px")};
	opacity: ${(props) => (props.visible ? "1" : "0")};
	overflow: hidden;
	margin-bottom: 0.25rem;
	font-family: ${(props) => props.theme.fonts.body};

	padding: 8px 7px;
	transition: all 0.5s ease-in-out;
	align-items: center;
`;

const UploadingIcon = styled(AutoRenewIconComponent)``;

const UploadCompleteIcon = styled(CheckCircleIconComponent)`
	fill: #53ba4a;
`;

const UploadErrorIcon = styled(AlertIconOutlinedComponent)``;

const Message = styled.div`
	color: #fff;
	font-size: 12px;
	margin: 0 7px;
	min-width: 202px;
`;

const Details = styled.div`
	color: #fff;
	font-size: 11px;
	margin-left: 7px;
	margin-right: 7px;
	min-width: 202px;
	margin-top: 0.4rem;
`;

const ClickTarget = styled.div`
	cursor: pointer;
`;
