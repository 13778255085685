import type { Dictionary } from "@reduxjs/toolkit";
import type { Dict, ITrackingRoute } from "@somewear/model";
import { selectEcho, timestampToMoment } from "@somewear/model";
import type { RootState } from "@web/app/rootReducer";
import { createSelector } from "reselect";

import { selectGlobalDateFilter } from "../filters/filterSelectors";
import { selectAllTrackingRoutes } from "./trackingRoutesSlice";

const selectDescSortedTrackingRoutes = createSelector([selectAllTrackingRoutes], (routes) => {
	const sortedRoutes: ITrackingRoute[] = [...routes];
	sortedRoutes.sort((a, b) => {
		if (a.timestamp === undefined || b.timestamp === undefined) {
			console.error("A tracking route location doesn't have a timestamp");
			return 0;
		}
		return b.timestamp.seconds - a.timestamp.seconds;
	});
	return sortedRoutes;
});

export const selectLastKnownTrackingRoutesByDateRange = createSelector(
	[selectDescSortedTrackingRoutes, selectGlobalDateFilter],
	(routes, dateFilter) => {
		const filteredRoutes = routes.filter((r) => {
			if (dateFilter === undefined) return true;
			if (r.timestamp === undefined) return false;
			return (
				timestampToMoment(r.timestamp).valueOf() >= dateFilter.start &&
				timestampToMoment(r.timestamp).valueOf() <= dateFilter.end
			);
		});

		// todo: this should use route ids waiting for lazy route creation
		const userLastKnownTrackingRoutesMap: Dict<ITrackingRoute> = {};
		const lastKnownRoutes: ITrackingRoute[] = [];
		filteredRoutes.forEach((route) => {
			const userId = route.ownerId;
			if (userId === undefined) return;

			// only store the first (latest) route for a user
			if (userLastKnownTrackingRoutesMap[userId] === undefined) {
				userLastKnownTrackingRoutesMap[userId] = route;
				lastKnownRoutes.push(route);
			}
		});

		return lastKnownRoutes;
	}
);

export const selectLastKnownTrackingRouteIdsForDateRangeByUserId = createSelector(
	[selectLastKnownTrackingRoutesByDateRange],
	(routes) => {
		const userIdRouteIdDict: Dictionary<string> = {};
		routes.forEach((route) => {
			if (route.ownerId !== undefined) {
				userIdRouteIdDict[route.ownerId] = route.id;
			}
		});

		return userIdRouteIdDict;
	}
);

/*
export const selectLiveTrackingRoutes = createSelector(
	[selectLastKnownTrackingRoutesByDateRange],
	(routes) => {
		return routes.filter((route) => isUserLive(moment(route.location!.timestamp)));
	}
);
*/

export const selectTrackingRoutesForUserId = createSelector(
	[selectAllTrackingRoutes, selectEcho<string, RootState>],
	(routes, userId) => {
		return routes.filter((it) => it.ownerId === userId);
	}
);
