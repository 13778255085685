import type { PayloadAction } from "@reduxjs/toolkit";
import type {
	ArchiveMembersResponse,
	InviteMembersRequest,
	InviteMembersResponse,
	UpdateWorkspaceAccount,
	UpdateWorkspaceAccountsResponse,
	UpdateWorkspaceRoleRequest,
	UpdateWorkspaceRoleResponse,
	UserResponse,
} from "@somewear/api";
import { assetActions, emitUserAccountChangeFromServer } from "@somewear/asset";
import { signedOut } from "@somewear/auth";
import { grpc, someGrpc } from "@somewear/grpc";
import type { ActionSetEpic } from "@somewear/model";
import { createActionSetEpicHandler } from "@somewear/model";
import type { RootState } from "@web/app/rootReducer";
import type { Epic } from "redux-observable";
import { combineEpics, ofType } from "redux-observable";
import type { OperatorFunction } from "rxjs";
import { from, of } from "rxjs";
import { filter, mergeMap, takeUntil } from "rxjs/operators";

const updateWorkspaceAssetEpic: ActionSetEpic<
	UpdateWorkspaceAccount.AsObject,
	UpdateWorkspaceAccountsResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(action$, state$, assetActions.update, (payload) =>
		grpc.prepareRequestWithPayload(someGrpc.updateWorkspaceUser, payload.data)
	);
};

const fulfilledUpdateWorkspaceAssetEpic: Epic<PayloadAction<any>> = (action$) =>
	action$.pipe(
		filter(assetActions.update.fulfilled.match),
		mergeMap((response) => {
			const list = response.payload.data.updatedUserAccounts?.responsesList;
			if (list !== undefined) {
				return from(list);
			} else {
				return of(undefined);
			}
		}),
		filter((account) => account !== undefined) as OperatorFunction<
			UserResponse.AsObject | undefined,
			UserResponse.AsObject
		>,
		mergeMap((account) => {
			return of(emitUserAccountChangeFromServer(account));
		}),
		takeUntil(action$.pipe(ofType(signedOut.type)))
	);

const inviteWorkspaceMembersEpic: ActionSetEpic<
	InviteMembersRequest.AsObject,
	InviteMembersResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		assetActions.add,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.addWorkspaceMembers, payload.data),
		(payload) => {
			return {
				onPending: `Inviting ${
					payload.data.emailsList.length +
					payload.data.identityIdsList.length +
					payload.data.usernamesList.length
				} members.`,
				onRejected: `Error inviting ${
					payload.data.emailsList.length +
					payload.data.identityIdsList.length +
					payload.data.usernamesList.length
				} members.`,
				onFulfilled: `Invited ${
					payload.data.emailsList.length +
					payload.data.identityIdsList.length +
					payload.data.usernamesList.length
				} members.`,
			};
		}
	);
};

const removeWorkspaceMembersEpic: ActionSetEpic<string[], ArchiveMembersResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		assetActions.remove,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.removeWorkspaceMembers, payload.data)
		/*(payload) => {
			return {
				onPending: `Removing ${payload.data.length} accounts...`,
				onRejected: `Error removing ${payload.data.length} accounts.`,
				onFulfilled: `Removed ${payload.data.length} accounts.`,
			};
		}*/
	);
};

const assignRoleEpic: ActionSetEpic<
	UpdateWorkspaceRoleRequest.AsObject,
	UpdateWorkspaceRoleResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		assetActions.assignRole,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.assignWorkspaceRole, payload.data),
		() => {
			return {
				onPending: `Updating the member's access.`,
				onRejected: `Error updating the member's access.`,
				onFulfilled: `Successfully updated the member's access.`,
			};
		}
	);
};

export default combineEpics<any, any, RootState>(
	inviteWorkspaceMembersEpic,
	removeWorkspaceMembersEpic,
	assignRoleEpic,
	updateWorkspaceAssetEpic,
	fulfilledUpdateWorkspaceAssetEpic
);
