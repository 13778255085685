import { JoinWorkspaceRequest, SubscriptionRequest } from "@somewear/api";
import type { IAuthUser } from "@somewear/auth";
import {
	Api,
	AuthController,
	isSomewearAuthService,
	RestClient,
	selectActiveOrganizationId,
	setUser,
	UserSource,
} from "@somewear/auth";
import { selectDevicesForActiveWorkspace } from "@somewear/device";
import { grpc, someGrpc } from "@somewear/grpc";
import { resetState, Sentry } from "@somewear/model";
import type { DeviceStatusSummary } from "@somewear/settings";
import { selectDeviceSummary, selectDeviceSummaryForActiveWorkspace } from "@somewear/settings";
import { selectAllSubscriptions } from "@somewear/subscription";
import Paths from "@web/common/Paths";
import { deleteToken } from "firebase/messaging";
import { StatusCode } from "grpc-web";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { createSelector } from "reselect";
import { BehaviorSubject, of, Subject } from "rxjs";
import { map, mergeMap, switchMap } from "rxjs/operators";

import { refreshInitialAppData, setPushToken, unauthorizedError } from "./appSlice";
import { selectDevicesForActiveOrganization } from "./devices/device.selectors";
import type { RootState } from "./rootReducer";
import store from "./store";

export function usePrevious<T>(value: T | undefined): T | undefined {
	const ref = useRef<T | undefined>();
	useEffect(() => {
		ref.current = value;
	}, [ref, value]);
	return ref.current;
}

export function useRegisterOnAuthServiceLoaded(callback: (loaded: boolean) => void) {
	useEffect(() => {
		if (AuthController.isServiceLoaded) {
			callback(true);
		} else {
			AuthController.service$.subscribe((service) => {
				callback(true);
			});
		}
	}, [callback]);
}

export function useRegisterToAuthChanges(
	authServiceLoaded: boolean,
	workspaceToken: string,
	setWorkspaceToken: (token: string) => void,
	onAuthStateChange: (user: IAuthUser | undefined) => void
) {
	const dispatch = useDispatch();

	useEffect(() => {
		let unregisterAuthObserver = () => {};

		AuthController.controller.service$.subscribe((service) => {
			const subject$ = new Subject<IAuthUser | undefined>();
			subject$.subscribe((user) => {
				onAuthStateChange(user);
			});
			unregisterAuthObserver = service.onAuthStateChanged(subject$);
		});

		return function cleanup() {
			console.log("app effect ended");
			if (unregisterAuthObserver !== undefined) unregisterAuthObserver();
		};
	}, [onAuthStateChange]);

	useEffect(() => {
		// console.log(`app effect started; workspaceToken=${workspaceToken}`);

		// Store workspace token in case the user messes up or refreshes
		if (workspaceToken.isNotEmpty()) {
			localStorage.setItem("workspaceToken", workspaceToken);
		} else {
			const storedToken = localStorage.getItem("workspaceToken");
			if (storedToken != null && storedToken.isNotEmpty()) {
				setWorkspaceToken(storedToken);
			}
		}
	}, [dispatch, workspaceToken, setWorkspaceToken, onAuthStateChange]);
}

export function useActiveAccountChangeHandler(
	authServiceLoaded: boolean,
	setAuthLoaded: (val: boolean) => void,
	onAuthStateChange: (user: IAuthUser | undefined) => void
) {
	const activeOrganizationId = useSelector(selectActiveOrganizationId);
	const prevActiveOrganizationId = useRef<string | undefined>(activeOrganizationId);
	useEffect(() => {
		if (!authServiceLoaded) return;
		const orgChanged =
			prevActiveOrganizationId.current !== undefined &&
			prevActiveOrganizationId.current !== activeOrganizationId;
		if (orgChanged) {
			batch(() => {
				UserSource.getInstance().setup(undefined);
				setAuthLoaded(false);
				onAuthStateChange(AuthController.service.getCurrentAuthUser());
			});
		}
		prevActiveOrganizationId.current = activeOrganizationId;
	}, [
		authServiceLoaded,
		onAuthStateChange,
		setAuthLoaded,
		activeOrganizationId,
		prevActiveOrganizationId,
	]);
}

export function usePaidUser(): boolean {
	return useSelector((state: RootState) => {
		const subscriptions = selectAllSubscriptions(state);
		return subscriptions
			.filter((subscription) => subscription.plan !== SubscriptionRequest.Plan.NONE)
			.isNotEmpty();
	});
}

export function useAnonymous(): boolean {
	return useSelector((state: RootState) => Boolean(state.app.user?.isAnonymous));
}

export function useDeviceStatusForActiveWorkspace(): DeviceStatusSummary[] {
	return useSelector(selectDeviceSummaryForActiveWorkspace);
}

export const selectDeviceSerialsForActiveWorkspace = createSelector(
	selectDevicesForActiveWorkspace,
	(devices) => {
		return devices.map((device) => device.serial);
	}
);

export const selectDeviceSerialsForActiveOrganization = createSelector(
	selectDevicesForActiveOrganization,
	(devices) => {
		return devices.map((device) => device.serial);
	}
);

export const selectDevicesByGroup = (state: RootState, organization: boolean) => {
	if (organization) return selectDevicesForActiveOrganization(state);
	else return selectDevicesForActiveWorkspace(state);
};

export const selectDeviceSerialsByGroup = (state: RootState, organization: boolean) => {
	if (organization) return selectDeviceSerialsForActiveOrganization(state);
	else return selectDeviceSerialsForActiveWorkspace(state);
};

// WARNING: this selector does not memoize correctly, use: _.isEqual
export const selectDeviceStatusBySerial = (state: RootState, serial: string) => {
	return selectDeviceSummary(state).find((status) => status.serial === serial);
};

// WARNING: this selector does not memoize correctly, use: _.isEqual
export const selectDeviceStatusByAssetIdentityId = (state: RootState, assetIdentityId?: string) => {
	if (assetIdentityId === undefined) return [];
	return selectDeviceSummary(state).filter(
		(status) => status.assignedAsset?.id === assetIdentityId
	);
};

export function useIsTouchEnabled$(): BehaviorSubject<boolean> {
	const subject = useMemo(() => {
		return new BehaviorSubject(false);
	}, []);

	const onTouch = useCallback(() => {
		subject.next(true);
		window.removeEventListener("touchstart", onTouch);
	}, [subject]);

	useEffect(() => {
		const isTouchEnabled = "ontouchstart" in window || navigator.maxTouchPoints > 0;

		if (isTouchEnabled) subject.next(true);

		window.addEventListener("touchstart", onTouch);
	}, [subject, onTouch]);

	return subject;
}

export function usePageVisibility() {
	const [isDocumentVisible, setIsDocumentVisible] = useState(true);

	useEffect(() => {
		const onChange = (ev: Event) => {
			console.log("onVisibilityChange", document.visibilityState);
			setIsDocumentVisible(!document.hidden);
		};
		const onFreeze = (ev: Event) => {
			console.log("onFreeze", ev);
		};

		const onResume = (ev: Event) => {
			console.log("onResume", ev);
		};

		document.addEventListener("visibilitychange", onChange);
		document.addEventListener("freeze", onFreeze);
		document.addEventListener("resume", onResume);
		return () => {
			document.removeEventListener("visibilitychange", onChange);
			document.removeEventListener("freeze", onFreeze);
			document.removeEventListener("resume", onResume);
		};
	}, [setIsDocumentVisible]);

	return isDocumentVisible;
}

export function useBeforeUnload() {
	const [isUnloading, setIsUnloading] = useState(true);

	const eventName = "beforeunload";
	const listener = () => setIsUnloading(true);

	document.addEventListener(eventName, listener, false);

	useEffect(() => {
		return () => {
			document.removeEventListener(eventName, listener);
		};
	});

	return isUnloading;
}

export function useWorkspaceTokenState(props: RouteComponentProps) {
	function parseWorkspaceTokenQueryParam(props: RouteComponentProps) {
		const query = props.location.search.substring(1);
		if (query.isEmpty()) return "";

		const parts = query.split("&");
		const found = parts.find((v) => v.startsWith("w="));
		if (found === undefined) return "";

		return found.substring(2);
	}
	return useState(parseWorkspaceTokenQueryParam(props));
}

export function useLogoutCallback(
	props: RouteComponentProps,
	setAuthLoaded: (value: boolean) => void
) {
	const dispatch = useDispatch();
	const upgrade = useSelector((state: RootState) => state.app.upgrade);

	return useCallback(() => {
		const handleLogout = () => {
			// Clear UserSource after sign out
			UserSource.getInstance().clear();

			const localUpgrade = upgrade;
			batch(() => {
				dispatch(setUser(undefined));
				dispatch(setPushToken(undefined));
				dispatch(resetState());
			});

			if (!localUpgrade) {
				props.history.push(Paths.Root);
			}
			setAuthLoaded(true);
		};

		const messaging = Api.getMessaging();
		if (messaging !== undefined) {
			deleteToken(messaging)
				.then((r) => {
					console.log(r);
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					handleLogout();
				});
		} else {
			handleLogout();
		}
	}, [dispatch, props.history, upgrade, setAuthLoaded]);
}

export function useLoginCallback(
	workspaceToken: string,
	setWorkspaceToken: (token: string) => void
) {
	const dispatch = useDispatch();
	return useCallback(
		(user: IAuthUser) => {
			UserSource.getInstance().setup(user);

			RestClient.init(store);
			Sentry.configureScope((scope) => scope.setUser({ id: user.id }));

			of(user)
				.pipe(
					mergeMap((user) => {
						// Join workspace if token is available.
						if (workspaceToken.isNotEmpty()) {
							localStorage.removeItem("workspaceToken");
							setWorkspaceToken("");
							const request = new JoinWorkspaceRequest();
							request.setToken(workspaceToken);

							// Join workspace and update activeWorkspace
							return grpc
								.prepareRequestWithPayload(someGrpc.joinWorkspaceByKey, request)
								.pipe(
									map((response) => {
										// TODO: tell user it failed
										if (!response) return;

										// Update active store if we can
										const uid = user?.id;
										if (uid) {
											console.debug(
												"joinWorkspace: success – will assign workspace as default"
											);
											UserSource.getInstance().updateActiveWorkspaceId(
												response.toObject().id
											);
										}

										return response;
									})
								);
						} else {
							return of(user);
						}
					}),
					switchMap(() => {
						// if we're using the somewear auth service, refresh the access token
						const authService = AuthController.service;
						if (isSomewearAuthService(authService)) {
							return authService.refreshAccessToken$();
						} else {
							return of(undefined);
						}
					}),
					map((r) => {
						// setAuthLoaded(true);
						dispatch(refreshInitialAppData());
					})
				)
				.subscribe(
					() => {
						console.log("loading new user");
					},
					(e) => {
						console.error(`Failed to initialize the app;`, e);
						Sentry.captureException(e);
						if (e.code === StatusCode.UNAUTHENTICATED) {
							dispatch(unauthorizedError());
							return;
						}
					}
				);
		},
		[dispatch, workspaceToken, setWorkspaceToken]
	);
}
