import type { DeviceActivationStateTypeMap, DevicePlanResponse } from "@somewear/api";
import { DeviceRecord } from "@somewear/api";

export interface IDevice extends DeviceRecord.AsObject {
	plan?: DevicePlanResponse.AsObject;
	hasDataUsage?: boolean;
	kbIncluded?: number;
	kbUsed?: number;
	isBorrowed?: boolean;
}

export enum DeviceActivationChange {
	NONE,
	ACTIVATE,
	SUSPEND,
}

export type DeviceActivationState =
	DeviceActivationStateTypeMap[keyof DeviceActivationStateTypeMap];

export interface IQueuedDeviceActivationChange {
	serial: string;
	currentState: DeviceActivationState;
	change: DeviceActivationChange;
}

const NODE_SERIAL_PREFIXES = ["PC0", "RC", "N"];

export function deviceHasNodeSerial(device: IDevice | undefined) {
	if (!device) return false;

	return NODE_SERIAL_PREFIXES.some((prefix) => device.serial.startsWith(prefix));
}

export const getDeviceModelName = (device: Pick<IDevice, "model"> | undefined) => {
	let modelName = "Other";

	switch (device?.model) {
		case DeviceRecord.Model.SWL1: {
			modelName = "Hotspot";
			break;
		}
		case DeviceRecord.Model.SHOUTNANO: {
			modelName = "Shout";
			break;
		}
		case DeviceRecord.Model.NODE: {
			modelName = "Node";
			break;
		}
	}
	return modelName;
};
