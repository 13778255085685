import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { type SosEventResponse } from "@somewear/api";
import { emitAssetAccountsDeleted } from "@somewear/asset";
import type { IError } from "@somewear/model";
import { clearWorkspace } from "@somewear/workspace";

import type { SosState } from "./sos.state";

const adapter = createEntityAdapter<SosEventResponse.AsObject>({
	selectId: (entity) => entity.sessionId,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllSosEvents,
	selectById: selectSosEventById,
	selectIds: selectAllSosEventSessionIds,
} = adapter.getSelectors((state: SosState) => state.sosEvents);

const sosSlice = createSlice({
	name: "sos",
	initialState: adapter.getInitialState(),
	reducers: {
		apiSosEventsRequest() {},
		apiSosEventsError(state, action: PayloadAction<IError>) {},
		apiSosEventsSuccess(state, action: PayloadAction<SosEventResponse.AsObject[]>) {
			action.payload.forEach((event) => {
				const prevEvent = adapter.getSelectors().selectById(state, event.sessionId);
				if (prevEvent?.timestamp !== undefined) {
					if (event.timestamp!.seconds > prevEvent.timestamp.seconds) {
						adapter.upsertOne(state, event);
					}
				} else {
					adapter.upsertOne(state, event);
				}
			});
		},
	},
	extraReducers: (builder) => {
		builder.addCase(clearWorkspace, (state, action) => {
			const deletedItems = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => it.workspaceId === action.payload);
			adapter.removeMany(
				state,
				deletedItems.map((it) => it.id)
			);
		});
		builder.addCase(emitAssetAccountsDeleted, (state, action) => {
			// when an account is deleted, delete the related data
			const deletedAccountIds = action.payload.map((it) => it.id);
			const deletedItems = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => deletedAccountIds.includes(it.userId));
			adapter.removeMany(
				state,
				deletedItems.map((it) => it.id)
			);
		});
	},
});

export const { apiSosEventsRequest, apiSosEventsError, apiSosEventsSuccess } = sosSlice.actions;

export default sosSlice;
