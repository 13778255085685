import { createSelector } from "reselect";

import { selectAllOrganizationApiKeys } from "./apiKeys.slice";

export const selectOrganizationApiKeysSorted = createSelector(
	[selectAllOrganizationApiKeys],
	(apiKeys) => {
		return apiKeys.sort((a, b) => {
			return (a.createdDate?.seconds ?? 0) > (b.createdDate?.seconds ?? 0) ? -1 : 1;
		});
	}
);
