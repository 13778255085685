import { grpc, someGrpc } from "@somewear/grpc";
import { actionSetEpicHandlerBuilder } from "@somewear/model";
import { combineEpics } from "redux-observable";

import { sensorsActions } from "./sensors.actions";

const getSensorsEpic = actionSetEpicHandlerBuilder(sensorsActions.getSensors, () =>
	grpc.prepareRequest(someGrpc.getSensors)
);

export const sensorsEpics = combineEpics<any, any, any>(getSensorsEpic);
